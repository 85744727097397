import lodash from 'lodash';
import React, {Component} from 'react';
import {computed, observable, action} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';

import inject from '../../../../hoc/injectHoc';
import {PAYMENT_PLAN_ANNUAL_ANNUAL, PAYMENT_PLAN_ANNUAL_MONTHLY, PAYMENT_PLAN_MONTHLY} from '../../../../../constants/subscriptionPlanConstants';
import LoadingIcon from '../../../../common/loadingIcon/LoadingIcon';
import {centsToDollars} from '../../../../../utils/mathHelper';
import './orderDetails.scss';

const TEN_PERCENT = 0.1;
const FIVE_PERCENT = 0.05;
const ONE_YEAR_MONTHS = 12;

const emptyOrderDetailsForm = {
  billingTypeSelection: PAYMENT_PLAN_MONTHLY
};

/**
 * The PlanOrderDetails component.
 */
class PlanOrderDetails extends Component {
  /**
   * User input fields
   *
   * @type {string}
   */
  @observable orderDetailsForm = lodash.clone(emptyOrderDetailsForm);

  /**
   * The validation errors for the form.
   *
   * @type {Map}
   */
  @observable validationErrors = new Map();

  /**
   * Calculate discounts for year commitments
   * 10% for a year plan paid upfront
   * 5% for a year contract paid monthly
   *
   * @param {number} price
   * @returns {number}
   */
  annualDiscount(price) {
    switch (this.orderDetailsForm.billingTypeSelection) {
      case PAYMENT_PLAN_ANNUAL_ANNUAL:
        return price * (1 - TEN_PERCENT);
      case PAYMENT_PLAN_ANNUAL_MONTHLY:
        return price * (1 - FIVE_PERCENT);
      default:
        return price;
    }
  }

  /**
   * Calculates the plan price per month
   */
  @computed get planPrice() {
    const {plan} = this.props;

    const price = this.annualDiscount(plan.priceCents);
    return centsToDollars(price);
  }

  /**
   * Calculate additional sign count
   */
  @computed get additionalSignCount() {
    const {userSigns} = this.props;
    const signCount = userSigns ? userSigns.length : 0;

    return (signCount > 0) ? (signCount - 1) : 0;
  }

  /**
   * Calculate and format the price of sign displays
   */
  @computed get displayPrice() {
    const {plan} = this.props;

    const price = plan.displayPriceCents;
    const total = this.annualDiscount(price);

    return centsToDollars(total);
  }

  /**
   * Calculate and format total purchase amount
   */
  @computed get totalPurchases() {
    const {plan} = this.props;

    const price = plan.priceCents + (this.additionalSignCount * plan.displayPriceCents);
    const total = this.annualDiscount(price);

    return centsToDollars(total);
  }

  /**
   * Calculate total billed amount for today
   */
  @computed get totalToday() {
    const {plan} = this.props;

    const price = plan.priceCents + (this.additionalSignCount * plan.displayPriceCents);
    let total = this.annualDiscount(price);

    if (this.orderDetailsForm.billingTypeSelection === PAYMENT_PLAN_ANNUAL_ANNUAL) {
      total *= ONE_YEAR_MONTHS;
    }

    return centsToDollars(total);
  }

  /**
   * Update contentRequestForm property
   *
   * @param {string} key
   * @param {object} value
   */
  @action updateProperty = (key, value) => {
    const currentValue = this.orderDetailsForm[key];

    if (key) {
      this.validationErrors.delete(key);
    }

    if (currentValue && currentValue.forEach) {
      const newValues = (Array.isArray(value)) ? value : [value];

      currentValue.replace(newValues);
      return;
    }

    this.orderDetailsForm[key] = String(value);
    this.props.billingType('billingType', this.orderDetailsForm.billingTypeSelection);
  };

  /**
   * Input onChange handler
   *
   * @param {{target: {}}} changeEvent
   */
  onChange = (changeEvent) => {
    const target = changeEvent.target;

    const value = (target.type === 'checkbox') ? target.checked : target.value;
    this.updateProperty(target.name, value);
  };

  /**
   * Renders the radio buttons for plan billing type options
   * @returns {{}}
   */
  planBillingTypesContent() {
    const billingTypes = [
      // can only use this feature with an upgraded Chargebee plan
      {
        type: PAYMENT_PLAN_ANNUAL_ANNUAL,
        text: 'Save 10% billed Yearly'
      },
      {
        type: PAYMENT_PLAN_ANNUAL_MONTHLY,
        text: 'Save 5% billed Monthly for 12 months'
      },
      {
        type: PAYMENT_PLAN_MONTHLY,
        text: 'Month to Month'
      }
    ];

    return billingTypes.map((billingType) => {
      return (
        <div key={billingType.type}>
          <label className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="billingTypeSelection"
              value={billingType.type}
              checked={this.orderDetailsForm.billingTypeSelection === billingType.type}
              onChange={this.onChange}
            />
            <span>{billingType.text}</span>
          </label>
        </div>
      );
    });
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      plan,
      buttonText,
      isLoading,
      onSubmit,
    } = this.props;

    const billingPeriod = this.orderDetailsForm.billingTypeSelection;

    return (
      <div
        className="order-details col-12 col-md-3 ml-md-5"
        data-cy="order-details-page"
      >
        <div className="order-details-top">
          {(plan.priceCents > 0) && (this.planBillingTypesContent())}
          <div className="font-weight-bold text-uppercase">Order details</div>
          <div className="d-flex justify-content-between">
            <div>
              <div className="selected-plan">{plan.name} Plan</div>
              <div>{billingPeriod === PAYMENT_PLAN_ANNUAL_ANNUAL ? '' : '(recurring monthly payment)'}</div>
            </div>
            <div>
              <div className={billingPeriod !== PAYMENT_PLAN_MONTHLY ? 'cost-markout' : ''}>{plan.price}/mo</div>
              {(billingPeriod !== PAYMENT_PLAN_MONTHLY) && (<div>{this.planPrice}/mo</div>)}
            </div>
          </div>
          {(this.additionalSignCount > 0) && (
            <div className="d-flex justify-content-between">
              <div>
                <div className="selected-plan">Additional Displays ({this.additionalSignCount})</div>
                <div>(recurring monthly payment)</div>
              </div>
              <div>{this.displayPrice}/sign/mo</div>
            </div>
          )}
        </div>
        <div className="order-details-middle">
          <div className="d-flex justify-content-between">
            <div>Total Purchases</div>
            <div>{this.totalPurchases}/mo {billingPeriod === PAYMENT_PLAN_ANNUAL_ANNUAL ? 'x 12' : ''}</div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Estimated Tax</div>
            <div>$ 0</div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <div className="font-weight-bold">Total Today</div>
            <div className="price">{this.totalToday}</div>
          </div>
        </div>
        <div className="order-details-bottom">
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-primary text-light"
              onClick={onSubmit}
              disabled={isLoading}
              data-cy="confirm-purchase-button"
            >
              {isLoading ? <span className="d-flex"><LoadingIcon size="em" /> {buttonText}</span> : buttonText}
            </button>
          </div>
        </div>
        <div className="disclaimer">*Once your purchase is complete, a receipt will be emailed to you.</div>
      </div>
    );
  }
}

PlanOrderDetails.propTypes = {
  buttonText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onboardingStore: MobxPropTypes.observableObject.isRequired,
  plan: PropTypes.shape({
    displayPrice: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    displayPriceCents: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    priceCents: PropTypes.number.isRequired
  }).isRequired,
  billingType: PropTypes.func,
  user: PropTypes.object,
  userSigns: PropTypes.object
};

PlanOrderDetails.wrappedComponent = {};
PlanOrderDetails.wrappedComponent.propTypes = {
  onboardingStore: MobxPropTypes.observableObject.isRequired
};

export default inject(PlanOrderDetails)(
  observer(PlanOrderDetails)
);
