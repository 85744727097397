import classNames from 'classnames';
import lodash from 'lodash';
import {action, observable, toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBan, faCaretDown, faCrop, faLock, faPencil} from '@fortawesome/free-solid-svg-icons';

import EditorSidebarTitle from '../../common/editorSidebarTitle/EditorSidebarTitle';
import AdvancedImageControls from './components/advancedImageControls/AdvancedImageControls';
import EditAlignmentControls from '../editAlignment/EditAlignmentControls';
import EditComposeControls from '../editCompose/EditComposeControls';
import EditEffectControls from '../editEffect/EditEffectControls';
import EditPositionControls from '../editPosition/EditPositionControls';
import EditTimelineControls from '../editTimeline/EditTimelineControls';
import EditUnitsControls from '../editUnits/EditUnitsControls';
import SelectFileModal, {FILE_TYPE_IMAGE} from '../../modals/selectFile/SelectFileModal';
import {actionUpdateComponent} from '../../../display/components/action/actionUpdateComponent';
import {actionUpdateVariableComponent} from '../../../display/components/action/actionUpdateVariableComponent';
import {getComposeForSource} from '../../../display/components/common/composeComponent';
import {cropComponent} from '../../../display/components/common/cropComponent';
import {interactionComponent} from '../../../display/components/common/interactionComponent';
import {
  cropShapeComponent, CIRCLE, ELLIPSE, RECTANGLE, TRIANGLE
} from '../../../display/components/common/cropShapeComponent';
import {getImageForSource, getImageFromSource} from '../../../display/components/type/imageComponent';
import ButtonAttentionWrapper from '../../common/buttonAttentionWrapper/ButtonAttentionWrapper';
import CustomIcon from '../../../components/common/customIcon/CustomIcon';

import './editImageControls.scss';

/**
 * The percentage for width and height when created or resetting crops.
 *
 * @const {number}
 */
const NEW_CROP_SIZE = '100%';

/**
 * The percentage for x and y when created or resetting crops.
 *
 * @const {number}
 */
const NEW_CROP_POSITION = '0%';

/**
 * The EditImageControls component.
 */
export class EditImageControls extends React.Component {
  /**
   * Whether or not the image choose/upload modal is open.
   *
   * @type {boolean}
   */
  @observable isModalOpen = false;

  /**
   * Whether or not the AI modal should open when the image choose/upload modal opens
   *
   * @type {boolean}
   */
  @observable forceOpenAIModal = false;

  /**
   * Whether or not the Unsplash modal should open when the image choose/upload modal opens
   *
   * @type {boolean}
   */
  @observable forceOpenUnsplashModal = false;

  /**
   * Whether or not the crop dropdown is open.
   *
   * @type {boolean}
   */
  @observable isCropDropdownOpen = false;

  /**
   * Updates the image entity when it is changed.
   *
   * @param {number} newFileId
   * @param {string} newUrl
   */
  onChangeImage = (newFileId, newUrl) => {
    if (newFileId === undefined || newUrl === undefined) {
      return;
    }

    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    const compose = entity.get('compose') || {};

    if (compose.variableName) {
      const element = entity.get('element');

      displayEditorStore.setVariable(element, compose.variableName, {
        fileId: newFileId,
        url: newUrl,
      });
    }

    const sourceVariables = toJS(displayEditorStore.variables);

    const entityUpdates = lodash.filter(game.entities, (gameEntity) => {
      if (gameEntity.get('id') === entity.get('id')) {
        return true;
      }

      if (!gameEntity.has('compose') || gameEntity.get('element') !== 'image') {
        return false;
      } else if (gameEntity.get('compose').variableName !== compose.variableName) {
        return false;
      }

      return true;
    }).reduce((final, updateEntity) => {
      const updateId = updateEntity.get('id');

      const imageSource = getImageForSource(updateEntity, game);
      const composeSource = getComposeForSource(updateEntity);

      imageSource.image.fileId = newFileId;
      imageSource.image.url = newUrl;

      final[updateId] = getImageFromSource(
        {...imageSource, ...composeSource},
        sourceVariables
      );
      return final;
    }, {});

    const actionParams = {
      entityId: entity.get('id'),
    };

    // This will rebuild the component for the entities using the new variable value.
    game.addAction(actionParams, actionUpdateVariableComponent(
      entityUpdates
    ));
  };

  /**
   * Triggered when the new image is selected by the modal.
   *
   * @param {{id: number, displayPath: string, placeholderImage: boolean}} newContent
   */
  @action onImageSelected = (newContent) => {
    this.isModalOpen = false;

    if (!newContent) {
      return;
    }

    if (newContent.displayPath && newContent.id) {
      this.onChangeImage(newContent.id, newContent.displayPath);
    } else if (newContent.placeholderImage) {
      this.onChangeImage(null, null);
    }
  };

  /**
   * Triggered when the image selector modal should open.
   */
  @action onOpenSelector = () => {
    this.isModalOpen = true;
    this.forceOpenAIModal = false;
    this.forceOpenUnsplashModal = false;
  };

  /**
   * Activates or deactivates the crop tool.
   *
   * @param {string} shape
   * @returns {function}
   */
  onClickCrop = (shape) => {
    return action(() => {
      const {entity, game} = this.props;

      const currentInteraction = entity.get('interaction');
      if (!currentInteraction.isActive) {
        return;
      }

      const newIsCropping = (!shape) ? !currentInteraction.isCropping : true;

      const actionParams = {
        entityId: entity.get('id'),
      };

      let components = interactionComponent(true, newIsCropping);

      if (!entity.get('crop')) {
        components = {
          ...components,
          ...cropComponent(NEW_CROP_POSITION, NEW_CROP_POSITION, NEW_CROP_SIZE, NEW_CROP_SIZE),
        };
      }

      if (shape) {
        components = {
          ...components,
          ...cropShapeComponent(shape)
        };
      }

      game.addAction(actionParams, actionUpdateComponent(components));
    });
  };

  /**
   * Removes the cropping on the image.
   */
  onRemoveCrop = () => {
    const {entity, game} = this.props;

    const currentInteraction = entity.get('interaction');
    if (!currentInteraction.isActive) {
      return;
    }

    const actionParams = {
      entityId: entity.get('id'),
    };

    let components = {
      ...interactionComponent(true, false),
      ...cropComponent(NEW_CROP_POSITION, NEW_CROP_POSITION, NEW_CROP_SIZE, NEW_CROP_SIZE),
      ...cropShapeComponent(RECTANGLE),
    };

    game.addAction(actionParams, actionUpdateComponent(components));
  };

  /**
   * Toggles the crop dropdown.
   */
  @action toggleCropDropdown = () => {
    this.isCropDropdownOpen = !this.isCropDropdownOpen;
  };

  /**
   * Opens generate image modal
   */
  @action onGenerateImageClick = () => {
    this.isModalOpen = true;
    this.forceOpenAIModal = true;
  };

  /**
   * Opens unsplash modal
   */
  @action onUnsplashClick = () => {
    this.isModalOpen = true;
    this.forceOpenUnsplashModal = true;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game
    } = this.props;
    const image = entity.get('image');

    const imageSource = image.url;

    let isLocked = false;
    let isCropLocked = false;
    if (entity.has('locked')) {
      const entityLock = entity.get('locked');
      if (lodash.includes(entityLock, 'time')) {
        // If the entity has time locked, then do not allow them to adjust anything.
        isLocked = true;
      }
      if (lodash.includes(entityLock, 'position') || lodash.includes(entityLock, 'size')) {
        isCropLocked = true;
      }
    }

    const interaction = entity.get('interaction');
    const cropButtonClasses = {
      active: (interaction) ? interaction.isCropping : false,
    };

    return (
      <div className="edit-image-controls">
        <div className="image-group">
          <EditorSidebarTitle title="Image" />

          {(isLocked) ? (
            <div className="can-not-edit">
              <span className="locked-text">
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faLock}
                />
                <span>Image Locked</span>
              </span>
            </div>
          ) : (
            <div className="image-selector px-4">
              <div className="image-wrapper">
                <button className="btn btn-link image-change-button" type="button" onClick={this.onOpenSelector}>
                  <img className="image-thumbnail" src={imageSource} />
                  <span className="image-edit-icon">
                    <FontAwesomeIcon icon={faPencil} />
                  </span>
                </button>
              </div>

              <div className="mt-2">
                <span className="ai-tool-text">Explore our latest AI Tool.</span>
                <ButtonAttentionWrapper>
                  <button
                    className="btn btn-dark-blue btn-sm"
                    type="button"
                    onClick={this.onGenerateImageClick}
                    gtm-id="ai-generate-image"
                  >
                    Generate Image
                    <span className="badge badge-primary ml-2 text-uppercase">Beta</span>
                  </button>
                </ButtonAttentionWrapper>
              </div>

              <div className="mt-2">
                <span className="ai-tool-text">Search for stock footage.</span>
                <button
                  className="btn btn-sm btn-outline-dark font-weight-bold d-flex align-items-center"
                  type="button"
                  onClick={this.onUnsplashClick}
                  gtm-id="search-unsplash-images"
                >
                  <CustomIcon
                    type="unsplash"
                    style={{
                      width: '24px',
                      height: '24px',
                    }}
                    className="mr-2"
                  />
                  Unsplash
                </button>
              </div>
            </div>
          )}
        </div>

        {(!isCropLocked) && (
          <div className="text-style control-group">
            <div className="group-header">
              <span className="group-header-label">Tools</span>
            </div>
            <div className="group-controls">
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <div><label>Crop</label></div>
                    <div className="align-buttons form-buttons">
                      <div className="btn-group">
                        <button
                          type="button"
                          className={classNames('btn btn-sm btn-light form-button', cropButtonClasses)}
                          onClick={this.onClickCrop(null)}
                        >
                          <FontAwesomeIcon icon={faCrop} />
                        </button>
                        <Dropdown isOpen={this.isCropDropdownOpen} toggle={this.toggleCropDropdown}>
                          <DropdownToggle
                            className={classNames('btn btn-sm btn-light form-button crop-dropdown')}
                          >
                            <FontAwesomeIcon icon={faCaretDown} />
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem
                              onClick={this.onClickCrop(RECTANGLE)}
                              toggle={false}
                            >Rectangle</DropdownItem>
                            <DropdownItem
                              onClick={this.onClickCrop(ELLIPSE)}
                              toggle={false}
                            >Ellipse</DropdownItem>
                            <DropdownItem
                              onClick={this.onClickCrop(CIRCLE)}
                              toggle={false}
                            >Circle</DropdownItem>
                            <DropdownItem
                              onClick={this.onClickCrop(TRIANGLE)}
                              toggle={false}
                            >Triangle</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <div><label>Reset Crop</label></div>
                    <div className="align-buttons form-buttons">
                      <div className="btn-group">
                        <button
                          type="button"
                          className={classNames('btn btn-sm btn-light form-button')}
                          onClick={this.onRemoveCrop}
                        >
                          <FontAwesomeIcon icon={faBan} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <EditComposeControls entity={entity} game={game} />
        <AdvancedImageControls entity={entity} game={game} displayEditorStore={displayEditorStore} />
        <EditPositionControls entity={entity} game={game} />
        <EditAlignmentControls entity={entity} game={game} />
        <EditUnitsControls entity={entity} game={game} />
        <EditTimelineControls entity={entity} game={game} />
        <EditEffectControls entity={entity} game={game} />

        <SelectFileModal
          isOpen={this.isModalOpen}
          onComplete={this.onImageSelected}
          type={FILE_TYPE_IMAGE}
          forceOpenAIModal={this.forceOpenAIModal}
          forceOpenUnsplashModal={this.forceOpenUnsplashModal}
        />
      </div>
    );
  }
}

EditImageControls.propTypes = {
  displayEditorStore: PropTypes.object.isRequired,
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditImageControls);
