/* eslint-disable curly */
import React, {Component} from 'react';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';

import inject from '../../../../hoc/injectHoc';
import nextLEDLogo from '../../../../../assets/images/next-logo.png';
import circleCrossPng from '../../../../../assets/icons/circle_cross.png';
import circleCheckPng from '../../../../../assets/icons/circle_check.png';
import circleCheckPrimaryPng from '../../../../../assets/icons/circle_check_primary.png';

import './planItem.scss';

const CENTS_IN_DOLLAR = 100;

/**
 * The PlanItem component.
 */
class PlanItem extends Component {
  /**
   * Renders the plan indicators
   *
   * @returns {{}}
   */
  renderIndicators = () => {
    const {planIndex, planCount} = this.props;

    let allIndicators = [];
    for (let i = 0; i < planCount; i += 1) {
      allIndicators.push(i);
    }

    return (
      <div className="plan-indicators">
        {allIndicators.map((i) => (<span key={i} className={classNames('plan-step-indicator', {'has-color': i <= planIndex})} />))}
      </div>
    );
  }

  /**
   * Renders the plan feature list
   *
   * @returns {{}}
   */
  renderFeatures = () => {
    const {plan} = this.props;
    const includedWithSign = plan.features && plan.features['Sign Manufacturer'];
    let planFeatures = [];

    if (plan.allowTrial) planFeatures.push('14 day FREE trial!');
    planFeatures.push(`${includedWithSign ? 'Limited ' : ''}Access to Presto Content Library`);
    if (plan.downloadCredits > 0) planFeatures.push(`${plan.downloadCredits} ${includedWithSign ? 'Basic ' : ''}Presto Sign Downloads / mo`);
    if (plan.requestCredits > 0) planFeatures.push(`${plan.requestCredits} Custom Sign Designs / mo`);
    if (plan.features && plan.features['Weather*']) planFeatures.push(`${plan.features['Weather*']} weather content`);
    if (plan.features && plan.features['Additional Feature']) planFeatures.push(plan.features['Additional Feature']);
    if (plan.features && plan.features['Additional Displays']) planFeatures.push(plan.features['Additional Displays']);

    return (<div className="plan-features">
      {planFeatures.map((feature) => {
        return (
          <div key={feature} className="d-flex py-1">
            <img src={plan.isRecommended ? circleCheckPrimaryPng : circleCheckPng} height={25} width={25} />
            <div className="plan-feature-content">{feature}</div>
          </div>
        );
      })}
    </div>);
  }

  /**
   * Renders the plan feature list
   *
   * @returns {{}}
   */
  renderMissingFeatures = () => {
    const {plan} = this.props;
    const planMissingFeatures = plan.features && Object.entries(plan.features).reduce((missingFeatures, [featureKey, featureValue]) => {
      if (featureValue === 'not-included') {
        return [...missingFeatures, featureKey];
      }
      return missingFeatures;
    }, []);

    return (<div className="plan-features missing">
      {planMissingFeatures.map((feature) => {
        return (
          <div key={feature} className="d-flex py-1">
            <img src={circleCrossPng} height={25} width={25} />
            <div className="plan-feature-content">{feature}</div>
          </div>
        );
      })}
    </div>);
  }

  /**
   * Renders the plan.
   *
   * @returns {{}}
   */
  render() {
    const {plan, disableButtons, onPlanSelect, onTrialSelect} = this.props;

    const priceDollars = plan.priceCents / CENTS_IN_DOLLAR;
    const planTagline = plan.features && plan.features.Tagline || 'Sign Content at Your Fingertips.';
    const planDescription = plan.features && plan.features.Description || 'Keep your signs looking good with stylish, up to date content.';
    const includedWithSign = plan.features && plan.features['Sign Manufacturer'];

    const showTrialPlanButton = plan.allowTrial && onTrialSelect && !plan.isActive;

    return (
      <>
      <div className={classNames('plan', {recommended: plan.isRecommended, popular: plan.isPopular, included: includedWithSign})}>
        {plan.isPopular && (<div className="plan-header background-secondary">Most Popular</div>)}
        {plan.isRecommended && (<div className="plan-header background-primary">Recommended</div>)}
        {this.renderIndicators()}
        <div className="plan-title">
          Content {plan.name}
        </div>
        <div className="plan-price">
          {includedWithSign ?
            (<><span className="plan-price-number">Included</span> <br />
            w/ {includedWithSign} Signs
            <img
              className="banner-text-logo"
              src={nextLEDLogo}
              alt="NextLED Logo"
            /></>)
            : (<>
            <sup className="plan-currency">$</sup>
            <span className="plan-price-number">{priceDollars}</span><span>/mo</span>
          </>)}
        </div>
        <div className="plan-description">
          <div className="plan-description-title">
            {planTagline}
          </div>
          <div className="plan-description-content">
            {planDescription}
          </div>
        </div>
        {this.renderFeatures()}
        {this.renderMissingFeatures()}

        <div className="plan-footer">
          {(showTrialPlanButton) && (<button
            type="button"
            className="btn plan-select-button trial"
            onClick={() => onTrialSelect()}
            disabled={disableButtons}
          >
            Get Started For Free
          </button>)}
          {onPlanSelect && (<button
            type="button"
            className="btn plan-select-button"
            onClick={() => onPlanSelect()}
            disabled={disableButtons || plan.isActive}
          >
            {plan.isActive ? 'Subscribed' : 'Choose Plan'}
          </button>)}
        </div>
      </div>
      </>
    );
  }
}

PlanItem.propTypes = {
  plan: PropTypes.object.isRequired,
  planCount: PropTypes.number.isRequired,
  planIndex: PropTypes.number.isRequired,
  disableButtons: PropTypes.bool,
  onPlanSelect: PropTypes.func,
  onTrialSelect: PropTypes.func,
};

PlanItem.wrappedComponent = {};
PlanItem.wrappedComponent.propTypes = {
};

export default inject(PlanItem)(
  observer(PlanItem)
);
