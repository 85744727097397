/**
 * The list of possible element types.
 *
 * @const {string[]}
 */
const ELEMENT_TYPES = [
  'circle',
  'icon',
  'image',
  'line',
  'rectangle',
  'triangle',
  'text',
  'feed',
  'timer',
  'video',
];

/**
 * The element component.
 *
 * @param {string} elementType
 * @returns {{element: string}}
 */
export function elementComponent(elementType) {
  if (!elementType) {
    throw new Error('No element type given to elementComponent.');
  }

  const safeElementType = String(elementType).toLowerCase();
  if (ELEMENT_TYPES.indexOf(safeElementType) === -1) {
    throw new Error(`Invalid element type '${safeElementType}' given to elementComponent.`);
  }

  return {
    element: safeElementType,
  };
}

/**
 * Gets the element component from the source item.
 *
 * @param {{type: string}} item
 * @returns {{element: string}}
 */
export function getElementFromSource(item) {
  if (!item.type) {
    return {};
  }
  return elementComponent(item.type);
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getElementForSource(entity) {
  if (!entity.has('element')) {
    return {};
  }

  const element = entity.get('element');
  return {
    type: element
  };
}
