import assignDeep from 'assign-deep';
import {toJS} from 'mobx';

import {getComposeForSource} from '../components/common/composeComponent';
import {getCropForSource} from '../components/common/cropComponent';
import {getCropShapeForSource} from '../components/common/cropShapeComponent';
import {getEffectForSource} from '../components/common/effectComponent';
import {getElementForSource} from '../components/common/elementComponent';
import {getGroupForSource} from '../components/common/groupComponent';
import {getInteractionForSource} from '../components/common/interactionComponent';
import {getLockedForSource} from '../components/common/lockedComponent';
import {getMaskForSource} from '../components/common/maskComponent';
import {getNameForSource} from '../components/common/nameComponent';
import {getPositionForSource} from '../components/common/positionComponent';
import {getSizeForSource} from '../components/common/sizeComponent';
import {getTimeForSource} from '../components/common/timeComponent';
import {getTransformForSource} from '../components/common/transformComponent';
import {getTransitionForSource} from '../components/common/transitionComponent';
import {getVisibleForSource} from '../components/common/visibleComponent';

import {getAudioForSource} from '../components/type/audioComponent';
import {getCircleForSource} from '../components/type/circleComponent';
import {getIconForSource} from '../components/type/iconComponent';
import {getImageForSource} from '../components/type/imageComponent';
import {getLineForSource} from '../components/type/lineComponent';
import {getRectangleForSource} from '../components/type/rectangleComponent';
import {getTriangleForSource} from '../components/type/triangleComponent';
import {getFeedForSource} from '../components/type/feedComponent';
import {getTextForSource} from '../components/type/textComponent';
import {getTimerForSource} from '../components/type/timerComponent';
import {getVideoForSource} from '../components/type/videoComponent';

import {GAME_TYPE_IMAGE, GAME_TYPE_VIDEO} from '../../stores/game/gameStore';

/**
 * Parses the final source JSON from the entities.
 *
 * @param {GameStore} game
 * @param {{forHistory: boolean}=} options
 * @returns {{resolution: {width: number, height: number}, endTime: number, entities: Array.<{type: string}>}}
 */
export function parseSourceFromGame(game, options) {
  const safeOptions = options || {};
  const entities = game.entities;

  safeOptions.hasTimeLine = game.hasTimeLine();

  let sourceEntities = [];
  if (entities && entities.map) {
    sourceEntities = entities.map((entity) => {
      return getSourceFromEntity(entity, game, safeOptions);
    }).filter((entity) => {
      if (safeOptions && safeOptions.forHistory) {
        return entity;
      }

      // Only keep entities that have a position, otherwise they will never show and so should not be saved.
      return Boolean(entity && entity.setup && entity.setup.position);
    });
  }

  return {
    version: 1,
    type: game.type,
    resolution: {
      height: game.resolution.height,
      width: game.resolution.width,
    },
    endTime: game.endTime,
    screenshotTimes: toJS(game.screenshotTimes),
    entities: sourceEntities
  };
}

/**
 * Parses the source JSON from the entity.
 *
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @param {{forHistory: boolean, hasTimeLine: boolean}=} options
 * @returns {{}}
 */
function getSourceFromEntity(entity, game, options) {
  if (!entity || !entity.has) {
    return {};
  }

  const commonSource = parseCommonComponents(entity, game, options);

  const entityElement = entity.get('element');
  if (!entityElement) {
    return null;
  }

  let typeSource = null;
  switch (entityElement) {
    case 'audio':
      typeSource = getAudioForSource(entity);
      break;
    case 'circle':
      typeSource = getCircleForSource(entity);
      break;
    case 'icon':
      typeSource = getIconForSource(entity);
      break;
    case 'image':
      typeSource = getImageForSource(entity, game);
      break;
    case 'line':
      typeSource = getLineForSource(entity);
      break;
    case 'rectangle':
      typeSource = getRectangleForSource(entity);
      break;
    case 'triangle':
      typeSource = getTriangleForSource(entity);
      break;
    case 'feed':
      typeSource = getFeedForSource(entity);
      break;
    case 'text':
      typeSource = getTextForSource(entity);
      break;
    case 'timer':
      typeSource = getTimerForSource(entity);
      break;
    case 'video':
      typeSource = getVideoForSource(entity);
      break;
    default:
      throw new Error(`Invalid entity type '${entityElement}' found.`);
  }

  return toJS(
    assignDeep(
      commonSource,
      typeSource
    )
  );
}

/**
 * Parses the item into common components.
 *
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @param {{forHistory: boolean, hasTimeLine: boolean}=} options
 * @returns {{}}
 */
function parseCommonComponents(entity, game, options) {
  return assignDeep(
    {},
    getElementForSource(entity),
    getComposeForSource(entity),
    getCropForSource(entity, game),
    getCropShapeForSource(entity),
    getEffectForSource(entity),
    getLockedForSource(entity),
    getGroupForSource(entity),
    getMaskForSource(entity),
    getNameForSource(entity),
    getPositionForSource(entity, game),
    getSizeForSource(entity, game),
    getTimeForSource(entity),
    getTransformForSource(entity),
    (options && options.hasTimeLine) ? getTransitionForSource(entity) : {},
    getVisibleForSource(entity),
    (options && options.forHistory) ? getInteractionForSource(entity) : {},
  );
}

/**
 * Creates the starting JSON for a new game/project.
 *
 * @param {number} width
 * @param {number} height
 * @param {number=} endTime
 * @returns {{}}
 */
export function parseSourceForNewGame(width, height, endTime) {
  if (!width || !height || width < 1 || height < 1) {
    throw new Error('Invalid dimensions given, width and height must be positive integers.');
  }

  const defaultEndTime = 5000;
  const safeEndTime = (endTime || endTime === 0) ? endTime : defaultEndTime;

  return {
    type: (!safeEndTime) ? GAME_TYPE_IMAGE : GAME_TYPE_VIDEO,
    resolution: {
      height,
      width,
    },
    endTime: safeEndTime,
    entities: [],
  };
}

/**
 * Gets file records from the entities.
 *
 * @param {Array.<{}>} entities
 * @returns {Array.<{id: number, filename: string}>}
 */
export function parseFilesFromEntities(entities) {
  if (!entities || !entities.length) {
    return [];
  }

  return entities.reduce((allFiles, entity) => {
    if (!entity.image && !entity.video) {
      return allFiles;
    }

    if (entity.image) {
      const image = entity.image;
      allFiles.push({
        id: image.fileId,
        filename: image.filename,
      });
    }

    if (entity.video) {
      const video = entity.video;
      allFiles.push({
        id: video.fileId,
        filename: video.filename,
      });
    }

    return allFiles;
  }, []);
}
