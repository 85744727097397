/* eslint-disable no-process-env, no-magic-numbers */

import env from './env';

export default {
  env: env,

  app: {
    url: process.env.APP_URL,
  },

  api: {
    url: process.env.API_URL || 'http://localhost:3031',
  },

  hub: {
    appUrl: process.env.HUB_APP_URL || 'http://localhost:3050',
    url: process.env.HUB_API_URL || 'http://localhost:3032',
    emulationCookiePrefix: process.env.EMULATION_COOKIE_PREFIX || '',
  },

  assets: {
    offSiteUrl: 'https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end',
    marketingUrl: 'https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/marketing',

    // Also update this in `src/styles/scss/_variables.scss`.
    fontUrl: 'https://static-project-content-assets.s3.us-east-2.amazonaws.com/front-end/fonts',
  },

  auth0: {
    clientId: process.env.AUTH0_CLIENT_ID || '',
    domain: process.env.AUTH0_ISSUER || '',
    audience: process.env.AUTH0_AUDIENCE || '',
    redirectUri: `${process.env.APP_URL}/dashboard`,

    // auth0 expects undefined if no cookie domain is provided
    cookieDomain: process.env.AUTH0_COOKIE_DOMAIN || undefined,
  },

  googleAnalytics: {
    trackId: process.env.GOOGLE_ANALYTICS_ID || null,
  },

  navBar: {
    showEnvWarnings: Boolean(env === 'staging'),
    scheduleSite: process.env.SCHEDULE_SITE || null,
  },

  sentry: {
    dsn: process.env.SENTRY_DSN || null,
  },

  chargebee: {
    site: process.env.CHARGEBEE_SITE || null
  },

  wordpress: {
    domain: process.env.WORDPRESS_DOMAIN
  },

  unsplash: {
    appName: process.env.UNSPLASH_APP_NAME,
  },
};
