import classNames from 'classnames';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faCaretRight} from '@fortawesome/free-solid-svg-icons';

import AddLayerControl from '../../controls/addLayer/AddLayerControl';
import EditCircleControls from '../../controls/editCircle/EditCircleControls';
import EditImageControls from '../../controls/editImage/EditImageControls';
import EditLineControls from '../../controls/editLine/EditLineControls';
import EditMultipleControls from '../../controls/editMultiple/EditMultipleControls';
import EditRectangleControls from '../../controls/editRectangle/EditRectangleControls';
import EditTriangleControls from '../../controls/editTriangle/EditTriangleControls';
import EditScreenshotTimeControl from '../../controls/editScreenshotTime/EditScreenshotTimeControl';
import EditFeedControls from '../../controls/editFeed/EditFeedControls';
import EditTextControls from '../../controls/editText/EditTextControls';
import EditTimelineControls from '../../controls/editTimeline/EditTimelineControls';
import EditVideoControls from '../../controls/editVideo/EditVideoControls';
import inject from '../../hoc/injectHoc';

import './displaySidebar.scss';

const typeMap = {
  circle: EditCircleControls,
  icon: EditTimelineControls,
  image: EditImageControls,
  line: EditLineControls,
  multiple: EditMultipleControls,
  rectangle: EditRectangleControls,
  triangle: EditTriangleControls,
  feed: EditFeedControls,
  text: EditTextControls,
  timer: EditTimelineControls,
  video: EditVideoControls,
};

/**
 * The DisplaySidebar component.
 *
 * @constructor
 */
export class DisplaySidebar extends React.Component {
  /**
   * Whether or not this sidebar is minimized.
   *
   * @type {boolean|string}
   */
  @observable minimized = 'auto';

  /**
   * Triggered when part of the component's mobX state is about to change.
   */
  @action componentWillReact() {
    const {game} = this.props.displayEditorStore;
    if (!game) {
      return;
    }

    let wasChanged = false;
    if (!game.activeEntity) {
      this.minimized = 'auto';
      wasChanged = true;
    } else if (this.minimized === 'auto') {
      this.minimized = false;
      wasChanged = true;
    }

    if (wasChanged) {
      this.triggerOnChange();
    }
  }

  /**
   * Triggers the on change minimization event.
   */
  triggerOnChange = () => {
    if (!this.props.onChangeMinimization) {
      return;
    }

    setTimeout(() => {
      this.props.onChangeMinimization(this.minimized);
    });
  };

  /**
   * Minimizes the display sidebar.
   */
  @action onMinimize = () => {
    this.minimized = true;

    this.triggerOnChange();
  };

  /**
   * Expands the display sidebar.
   */
  @action onExpand = () => {
    const {game} = this.props.displayEditorStore;
    if (!game.activeEntity) {
      // You can only expand if an item is active.
      return;
    }

    this.minimized = false;

    this.triggerOnChange();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {displayEditorStore, isCreator} = this.props;
    const {canMinimize, game, timer} = displayEditorStore;

    const activeEntity = game.activeEntity;
    const active = Boolean(activeEntity);

    const classes = {active};
    if (canMinimize && this.minimized) {
      classes['is-minimized'] = true;
    } else {
      classes['is-expanded'] = true;
    }

    let entityType = (activeEntity) ? activeEntity.get('element') : null;
    if (game.allActiveEntities.length > 1) {
      entityType = 'multiple';
    }

    const EntityControls = typeMap[entityType] || null;

    const controlsProps = {displayEditorStore, game};
    if (game.allActiveEntities.length > 1) {
      controlsProps.entities = game.allActiveEntities;
    } else {
      controlsProps.entity = activeEntity;
    }

    return (
      <div id="display-sidebar" className={classNames('system-sidebar', classes)}>
        {(canMinimize && this.minimized) ? (
          <div className="minimized-content">
            <a className="expand-arrow" onClick={this.onExpand}>
              <FontAwesomeIcon icon={faCaretRight} />
            </a>
          </div>
        ) : (
          <div className="display-sidebar-content">
            {(canMinimize) && (
              <a className="minimize-arrow" onClick={this.onMinimize}>
                <FontAwesomeIcon icon={faCaretDown} />
              </a>
            )}

            <div className="site-control-editor">
              {isCreator && <AddLayerControl game={game} timer={timer} />}

              {(EntityControls) && (
                <EntityControls {...controlsProps} />
              )}

              <EditScreenshotTimeControl game={game} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

DisplaySidebar.propTypes = {
  canMinimize: PropTypes.bool,
  displayEditorStore: MobxPropTypes.observableObject,
  isCreator: PropTypes.bool,
  onChangeMinimization: PropTypes.func,
};

DisplaySidebar.defaultProps = {
  canMinimize: true,
};

DisplaySidebar.wrappedComponent = {};
DisplaySidebar.wrappedComponent.propTypes = {
  displayEditorStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(DisplaySidebar)(
  observer(DisplaySidebar)
);
