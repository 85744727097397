import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';
import React from 'react';
import {observable, action} from 'mobx';
import {observer} from 'mobx-react';

import logo from '../../../../../assets/images/logo.png';
import ConfirmModal from '../../../../modals/confirm/ConfirmModal';

import './confirmPlanSelectModal.scss';

/**
 * The ConfirmPlanSelectModal component.
 */
class ConfirmPlanSelectModal extends React.Component {
  /**
   * Whether the credit confirmation modal is open
   *
   * @type {boolean}
   */
  @observable isConfirmOpen = true;

  /**
   * Whether the paid plan instructions are open
   *
   * @type {boolean}
   */
  @observable isPlanInstructionsOpen = false;

  /**
   * Handle when the confirmation modal is complete
   * Yes - user wants to choose a higher plan
   * No - user wants to continue with the included plan
   *
   * @param {boolean} wasConfirmed
   */
  @action onChooseUpgradePlanComplete = (wasConfirmed) => {
    const {onComplete} = this.props;

    if (wasConfirmed) {
      this.isConfirmOpen = false;
      this.isPlanInstructionsOpen = true;
    } else {
      this.isConfirmOpen = false;
      onComplete(false);
    }
  }

  /**
   * Handle when the plan instruction modal is closed
   *
   */
  @action onChoosePlanClicked = () => {
    const {onComplete} = this.props;

    this.isPlanInstructionsOpen = false;
    onComplete(true);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isConfirmOpen, isPlanInstructionsOpen, onChooseUpgradePlanComplete, onChoosePlanClicked} = this;

    return (
      <>
        <ConfirmModal
          isOpen={isConfirmOpen}
          bodyText="Would you like to use your $600 credit for an upgraded plan?"
          isYesNo={true}
          confirmText={(
            <div className="confirm-modal-header">
              <img
                className="confirm-modal-header-logo"
                src={logo}
                alt="Project Content Logo"
              />
              <p>Are you sure?</p>
            </div>
          )}
          onComplete={onChooseUpgradePlanComplete}
        />
        <Modal isOpen={isPlanInstructionsOpen}>
          <div className="confirm-modal-header">
            <img
              className="confirm-modal-header-logo"
              src={logo}
              alt="Project Content Logo"
            />
            <p>Use $600 Credit</p>
          </div>
          <ModalBody>
            <div className="confirm-modal-body">
              To use your $600 credit you must select either the Essential, Premium or Ultimate Plan.
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <button
              type="button"
              className="btn btn-secondary text-capitalize text-center w-100"
              onClick={onChoosePlanClicked}
            >
              Choose Plan
            </button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

ConfirmPlanSelectModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
};

export default observer(ConfirmPlanSelectModal);
