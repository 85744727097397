import classNames from 'classnames';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEllipsisH} from '@fortawesome/free-solid-svg-icons';
import {faFolder} from '@fortawesome/free-regular-svg-icons';

import {GENERAL_FOLDER_USER_ID} from '../../../../../constants/userConstants';
import LoadingIcon from '../../../../common/loadingIcon/LoadingIcon';
import inject from '../../../../hoc/injectHoc';
import GenerateImageModal from '../../../generateImageModal/GenerateImageModal';
import ButtonAttentionWrapper from '../../../../common/buttonAttentionWrapper/ButtonAttentionWrapper';
import CustomIcon from '../../../../common/customIcon/CustomIcon';
import UnsplashImageModal from '../../../unsplashImageModal/UnsplashImageModal';

import './userFileFolders.scss';

/**
 * The folder navigation component.
 *
 * @returns {{}}
 */
class UserFileFolders extends React.Component {
  /**
   * flag if generate image modal is open
   *
   * @type {boolean}
   */
  @observable isGenerateImageModalOpen = false;

  /**
   * flag if unsplash modal is open
   *
   * @type {boolean}
   */
  @observable isUnsplashImageModalOpen = false;

  /**
   * Loads user based on session
   */
  @action componentDidMount() {
    const {autoLoad, folderType, apiFileGetUserFoldersStore} = this.props;

    apiFileGetUserFoldersStore.refresh(folderType);
    if (autoLoad) {
      this.selectFirstFolder();
    }
  }

  /**
   * Opens generate image modal
   */
  @action onGenerateImageClick = () => {
    this.isGenerateImageModalOpen = true;
  };

  /**
   * Closes generate image modal
   */
  @action onHideGenerateImageModal = () => {
    this.isGenerateImageModalOpen = false;
  }

  /**
   * Opens unsplash modal
   */
  @action onUnsplashClick = () => {
    this.isUnsplashImageModalOpen = true;
  };

  /**
   * Closes unsplash modal
   */
  @action onHideUnsplashModal = () => {
    this.isUnsplashImageModalOpen = false;
  }

  /**
   * Auto selects the first folder.
   *
   */
  selectFirstFolder = () => {
    const {folderType, onSelect, apiFileGetUserFoldersStore} = this.props;

    apiFileGetUserFoldersStore.getPromise(folderType).then((folders) => {
      if (!folders || !folders.length) {
        return;
      }

      const firstFolder = folders.find((folder) => folder.userId !== GENERAL_FOLDER_USER_ID);

      onSelect(firstFolder || folders[0]);
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {folderType, onGeneratedImageSelect, onSelect, selected, apiFileGetUserFoldersStore, onUnsplashImageSelect} = this.props;

    return (
      <div className="user-file-folders">
        {apiFileGetUserFoldersStore.case(folderType, {
          fulfilled: (fileFolders) => fileFolders.map((fileFolder) => {
            if (!fileFolder.isDesign) {
              return null;
            }

            return (
              <div key={fileFolder.id} className="folder-group">
                <div className="folders-list-header d-flex align-items-center">
                  <span>
                    Design Folders
                  </span>

                  {apiFileGetUserFoldersStore.case(folderType, {
                    pending: () => <LoadingIcon size="em" />
                  })}

                  <FontAwesomeIcon
                    className="ml-auto font-weight-bold"
                    icon={faEllipsisH}
                    aria-hidden="true"
                  />
                </div>
                <div
                  className={classNames(
                    'folders-list-item d-flex align-items-center',
                    {active: String(fileFolder.id) === selected}
                  )}
                  onClick={() => onSelect(fileFolder)}
                >
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={faFolder}
                    aria-hidden="true"
                  />
                  <div>
                    {fileFolder.name}
                  </div>
                  <div className="folders-list-item-count ml-auto">
                    {fileFolder.contentCount}
                  </div>
                </div>
              </div>
            );
          })
        })}

        <div className="folder-group">
          <div className="folders-list-header d-flex align-items-center">
            <span>
              My File Folders
            </span>

            {apiFileGetUserFoldersStore.case(folderType, {
              pending: () => <LoadingIcon size="em" />
            })}
          </div>

          {apiFileGetUserFoldersStore.case(folderType, {
            fulfilled: (fileFolders) => {
              return (
                <div>
                  <div
                    className={classNames(
                      'folders-list-item d-flex align-items-center',
                      {active: 'media' === selected}
                    )}
                    onClick={() => onSelect({id: 'media'}, fileFolders.map((f) => f.id))}
                  >
                    <FontAwesomeIcon
                      className="mr-2"
                      icon={faFolder}
                      aria-hidden="true"
                    />
                    <div>
                      All Media
                    </div>
                  </div>
                  {fileFolders.map((fileFolder) => {
                    if (fileFolder.isDesign) {
                      return null;
                    }

                    return (
                      <div
                        key={fileFolder.id}
                        className={classNames(
                          'folders-list-item d-flex align-items-center',
                          {active: String(fileFolder.id) === selected}
                        )}
                        onClick={() => onSelect(fileFolder)}
                      >
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={faFolder}
                          aria-hidden="true"
                        />
                        <div>
                          {fileFolder.name}
                        </div>
                        <div className="folders-list-item-count ml-auto">
                          {fileFolder.contentCount}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            }
          })}

          <hr />

          <div>
            <span className="ai-tool-text">Explore our latest AI Tool.</span>
            <ButtonAttentionWrapper>
              <button
                className="btn btn-block btn-sm btn-dark-blue"
                type="button"
                onClick={this.onGenerateImageClick}
                gtm-id="ai-generate-image"
              >
                Generate Image
                <span className="badge badge-primary ml-2 text-uppercase">Beta</span>
              </button>
            </ButtonAttentionWrapper>
          </div>

          <div className="mt-3">
            <span className="unsplash-tool-text">Search for stock footage.</span>
            <button
              className="btn btn-sm btn-outline-dark font-weight-bold d-flex align-items-center"
              type="button"
              onClick={this.onUnsplashClick}
              gtm-id="search-unsplash-images"
            >
              <CustomIcon
                type="unsplash"
                style={{
                  width: '24px',
                  height: '24px',
                }}
                className="mr-2"
              />
              Unsplash
            </button>
          </div>

          <GenerateImageModal
            isOpen={this.isGenerateImageModalOpen}
            onCancel={this.onHideGenerateImageModal}
            onImageSelect={(url) => {
              this.onHideGenerateImageModal();
              onGeneratedImageSelect(url);
            }}
          />

          <UnsplashImageModal
            isOpen={this.isUnsplashImageModalOpen}
            onCancel={this.onHideUnsplashModal}
            onImageSelect={onUnsplashImageSelect}
          />
        </div>
      </div>
    );
  }
}

UserFileFolders.propTypes = {
  folderType: PropTypes.string.isRequired,
  onGeneratedImageSelect: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onUnsplashImageSelect: PropTypes.func.isRequired,

  apiFileGetUserFoldersStore: MobxPropTypes.observableObject,
  autoLoad: PropTypes.bool,
  selected: PropTypes.string,
};

UserFileFolders.wrappedComponent = {};
UserFileFolders.wrappedComponent.propTypes = {
  apiFileGetUserFoldersStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(UserFileFolders)(
  observer(UserFileFolders)
);
