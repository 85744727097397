import classNames from 'classnames';
import lodash from 'lodash';
import {toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import DisplayCircle from '../circle/DisplayCircle';
import DisplayIcon from '../icon/DisplayIcon';
import DisplayImage from '../image/DisplayImage';
import DisplayLine from '../line/DisplayLine';
import DisplayRectangle from '../rectangle/DisplayRectangle';
import DisplayTriangle from '../triangle/DisplayTriangle';
import DisplayFeed from '../feed/DisplayFeed';
import DisplayText from '../text/DisplayText';
import DisplayTimer from '../timer/DisplayTimer';
import DisplayVideo from '../video/DisplayVideo';
import {
  getPositionLeftAndTop,
  parseCSSEffects,
  parseCSSFilters,
  parseCSSTransforms
} from '../../../display/ecs/entityHelper';
import {actionInteractionComponent} from '../../../display/components/action/actionInteractionComponent';

import './displayEntity.scss';

const entityMap = {
  icon: DisplayIcon,
  image: DisplayImage,
  circle: DisplayCircle,
  line: DisplayLine,
  rectangle: DisplayRectangle,
  triangle: DisplayTriangle,
  feed: DisplayFeed,
  text: DisplayText,
  timer: DisplayTimer,
  video: DisplayVideo
};

/**
 * Active entities need have the highest zIndex in the display.
 * So this needs to be a very high number and higher than any other zIndex.
 *
 * @const {number}
 */
const ACTIVE_ENTITY_ZINDEX = 1000000;

/**
 * The DisplayEntity component.
 */
class DisplayEntity extends React.Component {
  /**
   * Gets the styles from the entity.
   *
   * @param {ObservableMap} entity
   * @param {{}} game
   * @returns {{styles: {}, topStyles: {}}}
   */
  getStylesFromEntity = (entity, game) => {
    const safeEntity = toJS(entity);

    const isSizeLocked = Boolean(
      safeEntity.locked
      && safeEntity.locked.indexOf('size') !== -1
      && safeEntity.locked.indexOf('position') !== -1
    );

    const gameResolution = game.resolution;
    const sizeModifier = 3;

    const topStyles = {
      height: gameResolution.height * sizeModifier,
      width: gameResolution.width * sizeModifier,
      position: 'absolute',
      top: `${0 - gameResolution.height}px`,
      left: `${0 - gameResolution.width}px`,
      clipPath: this.getClipPath(gameResolution),
    };

    const styles = {};
    if (safeEntity.size) {
      styles.width = safeEntity.size.width;
      styles.height = safeEntity.size.height;
    }
    if (safeEntity.position) {
      styles.position = 'absolute';

      const positionStyle = getPositionLeftAndTop(
        safeEntity.position.alignment,
        safeEntity.position,
        safeEntity.size,
        game
      );

      styles.top = gameResolution.height + positionStyle.top;
      styles.left = gameResolution.width + positionStyle.left;
      topStyles.zIndex = safeEntity.position.zIndex;
    }
    if (safeEntity.visible) {
      if (!safeEntity.visible.isVisible) {
        topStyles.visibility = 'hidden';
      }
      if (safeEntity.visible.hasOwnProperty('opacity')) {
        topStyles.opacity = safeEntity.visible.opacity;
      }
    }
    if (safeEntity.interaction && safeEntity.interaction.isActive) {
      if (!isSizeLocked) {
        topStyles.zIndex = ACTIVE_ENTITY_ZINDEX;
      }
    }

    const transformData = this.getTransformsForEntity(entity, {
      rotate: safeEntity.position.rotate
    });
    styles.transform = transformData.transform;
    styles.WebkitTransform = transformData.transform;

    if (transformData.perspectiveOrigin) {
      styles.perspectiveOrigin = transformData.perspectiveOrigin;
    }

    styles.filter = this.getFiltersForEntity(entity).filter;

    const effectStyles = this.getEffectsForEntity(entity);

    return {
      styles,
      topStyles: {
        ...topStyles,
        ...effectStyles,
      },
    };
  };

  /**
   * Gets the clip path for the entity to make sure it stays within the display.
   *
   * @param {{height: number, width: number}} gameResolution
   * @returns {{}}
   */
  getClipPath = (gameResolution) => {
    const height = gameResolution.height;
    const width = gameResolution.width;

    return `inset(${height}px ${width}px ${height}px ${width}px)`;
  };

  /**
   * Translates the transformations into css styles for the given entity.
   *
   * @param {ObservableMap} entity
   * @param {{}=} defaults
   * @returns {{transform: string, perspectiveOrigin: string}}
   */
  getTransformsForEntity = (entity, defaults) => {
    const transform = toJS(entity.get('transform') || {});

    if (defaults) {
      lodash.forEach(defaults, (propertyValue, propertyName) => {
        if (!transform[propertyName]) {
          transform[propertyName] = propertyValue;
        }
      });
    }

    return parseCSSTransforms(transform);
  };

  /**
   * Translates the filters into css styles for the given entity.
   *
   * @param {ObservableMap} entity
   * @returns {{filter: string}}
   */
  getFiltersForEntity = (entity) => {
    const mask = entity.get('mask');

    return parseCSSFilters(mask);
  };

  /**
   * Translates the effects into css styles for the given entity.
   *
   * @param {ObservableMap} entity
   * @returns {{}}
   */
  getEffectsForEntity = (entity) => {
    if (!entity.has('effect')) {
      return {};
    }

    const effect = entity.get('effect');

    return parseCSSEffects(effect);
  };

  /**
   * Gets whether or not the current entity is active.
   *
   * @param {ObservableMap} entity
   * @returns {boolean}
   */
  getIsEntityActive = (entity) => {
    if (!entity.has('interaction')) {
      return false;
    }

    const interaction = entity.get('interaction');
    return Boolean(interaction.isActive);
  };

  /**
   * Activate entity
   *
   * @param {React.MouseEvent<HTMLElement>|React.MouseEvent<SVGElement>} clickEvent
   * @param {ObservableMap} entity
   */
  activateEntity = (clickEvent, entity) => {
    const {game} = this.props;
    const holdingShift = Boolean(clickEvent && clickEvent.shiftKey);

    const actionParams = {
      entityId: entity.get('id'),
    };

    game.addAction(actionParams, actionInteractionComponent(true, holdingShift, false));
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {game, entity} = this.props;

    const elementType = entity.get('element') || null;
    if (!entity.has('visible')) {
      return null;
    } else if (!entityMap[elementType]) {
      throw new Error(`Invalid entity found, type ${elementType} has no map.`);
    }

    const DisplayEl = entityMap[elementType];

    const {styles, topStyles} = this.getStylesFromEntity(entity, game);

    return (
      <DisplayEl
        key={entity.get('id')}
        className={classNames({'is-active-entity': this.getIsEntityActive(entity)})}
        entity={entity}
        game={game}
        style={styles}
        topStyle={topStyles}
        onEntityClick={this.activateEntity}
      />
    );
  }
}

DisplayEntity.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(DisplayEntity);
