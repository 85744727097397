import lodash from 'lodash';
import {toJS, observable, action, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, {Component} from 'react';
import _throttle from 'lodash/throttle';

import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';

import LoadingIcon from '../loadingIcon/LoadingIcon';
import inject from '../../hoc/injectHoc';
import {SCREEN_SIZE_MEDIUM} from '../../../constants/bootstrapConstants';
import {PLAN_DATA, NEXTLED_SIGNUP_TYPE} from '../../../constants/subscriptionPlanConstants';
import {centsToDollars} from '../../../utils/mathHelper';

import PlanItem from './components/planItem/PlanItem';

import './subscriptionPlanTable.scss';

const UPDATE_PLAN_REASON_TRIAL = 'trial_expired';

/**
 * The SubscriptionPlanTable component.
 */
class SubscriptionPlanTable extends Component {
  /**
   * Whether the plans should be displayed in mobile view
   *
   * @type {boolean}
   */
  @observable isMobileView = false;

  /**
   * Plan that is showing when in tabbed mobile view
   *
   * @type {boolean}
   */
  @observable activePlanTab = null;

  /**
 * Determine if plan table should be in mobile view
 */
  checkWindowWidth = _throttle(() => {
    runInAction('setisMobileView', () => {
      const windowWidth = window.innerWidth;
      this.isMobileView = windowWidth < SCREEN_SIZE_MEDIUM;
    });
  // eslint-disable-next-line no-magic-numbers
  }, 500);

  /**
   * Triggered when the component first mounts to the page.
   */
  componentDidMount() {
    const {apiPlanGetAllStore} = this.props;

    apiPlanGetAllStore.refresh();

    this.checkPlansForSelected();

    // check window size immediately
    this.checkWindowWidth();
    window.addEventListener('resize', this.checkWindowWidth);
  }

  /**
   * Triggered when the component updates.
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.selectPlanId && this.props.selectPlanId !== prevProps.selectPlanId) {
      this.checkPlansForSelected();
    }
  }

  /**
   * Clean up window events to avoid memory leaks
   */
  @action componentWillUnmount() {
    window.removeEventListener('resize', this.checkWindowWidth);
  }

  /**
   * Selects the active plan tab
   *
   * @param {number} planId
   */
  @action selectActivePlanTab(planId) {
    this.activePlanTab = planId;
  }

  /**
   * Selects the plan if the selectPlanId matches a plan.
   */
  checkPlansForSelected = () => {
    const {apiPlanGetAllStore, onPlanSelect, selectPlanId} = this.props;

    if (selectPlanId && onPlanSelect) {
      apiPlanGetAllStore.getPromise().then((plans) => {
        const matchingPlan = lodash.find(toJS(plans), {id: selectPlanId});
        if (!matchingPlan) {
          return;
        }

        this.selectActivePlanTab(matchingPlan.id);

        onPlanSelect(this.cleanUpPlan(matchingPlan));
      });
    }
  };

  /**
   * Cleans up the plan object from the server.
   *
   * @param {{}} plan
   * @returns {{}}
   */
  cleanUpPlan = (plan) => {
    const cleaned = {
      ...plan,
      ...PLAN_DATA[plan.name],
      price: centsToDollars(plan.price),
      priceCents: plan.price,
      displayPrice: centsToDollars(plan.displayPrice),
      displayPriceCents: plan.displayPrice
    };

    return cleaned;
  };

  /**
   * Parses the plans into values that are more friendly for the display table.
   *
   * @param {Array<{}>} plans
   * @returns {Array<{}>}
   */
  parsePlansForTable = (plans) => {
    return plans.map((plan) => {
      return this.cleanUpPlan(plan);
    });
  };

  /**
   * Filter plans so user sees the plans relevant to them
   *
   * @param {Array<{}>} plans
   * @returns {Array<{}>}
   */
  filterPlansForTable = (plans) => {
    const {user} = this.props;
    const companyManufacturerSignupType = lodash.get(user, 'company.manufacturerSignupType');
    const planDisplayRulesByName = {
      'Basic': user && user.requiresPlanUpdate && user.planUpdateReason !== UPDATE_PLAN_REASON_TRIAL && !companyManufacturerSignupType,
      'NextLED': user && companyManufacturerSignupType === NEXTLED_SIGNUP_TYPE,
    };

    const trialPlan = lodash.find(toJS(plans), {name: 'Trial'});
    const plansToShow = plans.reduce((validPlans, plan) => {
      const mostExpensive = plans.every((i) => i.priceCents <= plan.priceCents);
      const planId = lodash.get(user, 'projectContentCompany.planId');
      const isActive = planId ? planId === plan.id || planId === trialPlan.id && plan.name === 'Essential' : false;
      const hasUsedTrial = user && user.projectContentCompany && user.projectContentCompany.hasUsedTrial;
      const planToShow = {
        ...plan,
        isActive,
        allowTrial: plan.allowTrial && !hasUsedTrial,
        isRecommended: mostExpensive,
      };

      const showPlan = planDisplayRulesByName[plan.name];

      if (plan.isVisible || showPlan) {
        return [...validPlans, planToShow];
      }

      return validPlans;
    }, []);
    return plansToShow;
  }

  /**
   * Renders the mobile tab view of plans.
   *
   * @returns {{}}
   */
  renderPlanMobileView = () => {
    const {apiPlanGetAllStore, onPlanSelect, disablePlanButtons, user} = this.props;

    const allPlans = this.parsePlansForTable(
      apiPlanGetAllStore.getFulfilled() || []
    );
    const trialPlan = lodash.find(toJS(allPlans), {name: 'Trial'});

    const plans = this.filterPlansForTable(allPlans);

    const companyManufacturerSignupType = lodash.get(user, 'company.manufacturerSignupType');

    // select the last plan if it's a customer from a manufacturer, otherwise show the first plan
    const defaultActivePlanTab = user && companyManufacturerSignupType && plans.length > 0 ? plans[plans.length - 1].id : plans[0].id;
    const activeTab = this.activePlanTab || defaultActivePlanTab;

    return (
      <div className="mt-4 m-2">
        <Nav tabs className="plan-list-tabs">
          {plans.map((plan) => (
            <NavItem key={plan.id}>
              <NavLink
                className={classNames({active: activeTab === plan.id})}
                onClick={() => this.selectActivePlanTab(plan.id)} key={plan.id}
              >
                {plan.name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab} className="pt-3">
          {plans.map((plan, idx) => (<TabPane tabId={plan.id} key={plan.id}>
            <PlanItem
              key={plan.id}
              plan={plan}
              planIndex={idx}
              planCount={plans.length}
              onPlanSelect={() => onPlanSelect(plan)}
              onTrialSelect={() => onPlanSelect(trialPlan)}
              disableButtons={disablePlanButtons}
            />
          </TabPane>))}
        </TabContent>
      </div>
    );
  }

  /**
   * Renders the full plan table.
   *
   * @returns {{}}
   */
  renderPlanTable = () => {
    const {apiPlanGetAllStore, onPlanSelect, disablePlanButtons} = this.props;

    const allPlans = this.parsePlansForTable(
      apiPlanGetAllStore.getFulfilled() || []
    );
    const trialPlan = lodash.find(toJS(allPlans), {name: 'Trial'});

    const plans = this.filterPlansForTable(allPlans);

    return (
      <div className="plan-list-container mt-4">
        <div className="plan-list row no-gutters">
          {
            plans.map((plan, idx) => {
              return (
                <div
                  className="col"
                  key={plan.id}
                >
                  <PlanItem
                    plan={plan}
                    planIndex={idx}
                    planCount={plans.length}
                    onPlanSelect={() => onPlanSelect(plan)}
                    onTrialSelect={() => onPlanSelect(trialPlan)}
                    disableButtons={disablePlanButtons}
                  />
                </div>
              );
            })
          }
        </div>
      </div>
    );
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiPlanGetAllStore} = this.props;

    return apiPlanGetAllStore.case({
      pre: () => (<LoadingIcon />),
      pending: () => (<LoadingIcon />),
      rejected: () => (
        <div className="subscription-plan-table">
          <div className="alert alert-warning">
            The plan data was not able to be loaded. Please refresh the page to try again.
          </div>
        </div>
      ),
      fulfilled: () => (
        <>{this.isMobileView ? this.renderPlanMobileView() : this.renderPlanTable()}</>
      ),
    });
  }
}

SubscriptionPlanTable.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject,
  apiPlanGetAllStore: MobxPropTypes.observableObject,
  disablePlanButtons: PropTypes.bool,
  onPlanSelect: PropTypes.func,
  selectPlanId: PropTypes.number,
  user: PropTypes.object
};

SubscriptionPlanTable.wrappedComponent = {};
SubscriptionPlanTable.wrappedComponent.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiPlanGetAllStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(SubscriptionPlanTable)(
  observer(SubscriptionPlanTable)
);
