import React from 'react';
import PropTypes from 'prop-types';

import './buttonAttentionWrapper.scss';

/**
 * The ButtonAttentionWrapper component.
 */
export class ButtonAttentionWrapper extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <div className="btn-with-spiral">
        {this.props.children}
      </div>
    );
  }
}

ButtonAttentionWrapper.propTypes = {
  children: PropTypes.element,
};

export default ButtonAttentionWrapper;
