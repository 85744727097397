import {action} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';

import inject from '../../hoc/injectHoc';

import './selectFeedModal.scss';

/**
 * The default modal title.
 * @const {string}
 */
const DEFAULT_TITLE = 'Select Feed';

/**
 * The real time feed items
 */
const FEED_ITEMS = [
  {
    name: 'Current Temp',
    type: 'Weather',
    placeholder: '100°',
    markdown: '{|}{$1.00}{%1}{100}{#777777}{arial}100°{!arial}{!#777777}{!100}{!%1}{!$1.00}{!|}',
  },
  {
    name: 'Current Time',
    type: 'CurrentTime',
    placeholder: '12:00',
    markdown: '{|}{$1.00}{%1}{100}{#777777}{arial}12:00{!arial}{!#777777}{!100}{!%1}{!$1.00}{!|}',
  }
];

/**
 * The SelectFeedModal component.
 */
export class SelectFeedModal extends React.Component {
  /**
   * Triggered when the component has just updated.
   *
   * @param {{isOpen: boolean}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Initializes the modal.
   */
  @action initializeModal = () => {
    // Put any initializations here.
  };

  /**
   * Triggered when the modal is closed without finishing.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete(false);
  };

  /**
   * Triggered when the modal is closed after choosing a folder.
   * @param {{}} feedItem
   */
  @action onCompleteModal = async (feedItem) => {
    const {onComplete} = this.props;

    onComplete(feedItem);
  };

  /**
   * Render the feed item
   * @param {{}} feedItem
   *
   * @returns {{}}
   */
  renderFeedItem = (feedItem) => {
    return (
      <div key={feedItem.name} className="col-sm-12 col-lg-6 col-xl-3">
        <div className="d-flex flex-column feed-item" onClick={() => this.onCompleteModal(feedItem)}>
          <div className="feed-item-thumbnail">
            {feedItem.placeholder}
          </div>
          <div className="feed-item-info p-3">
            <div className="feed-item-name">
              {feedItem.name}
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      isOpen,
      title
    } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <Modal isOpen={isOpen} toggle={this.onCancelModal} className="select-feed-modal" centered>
        <ModalHeader toggle={this.onCancelModal}>{title}</ModalHeader>
        <ModalBody>
          <div className="modal-body-content-wrapper">
            <div className="select-feed-main">
              <div className="left-side">
                <div className="feed-type-list-item d-flex align-items-center active">
                  <div>
                    All Feeds
                  </div>
                  <div className="feed-type-list-item-count ml-auto">
                    2
                  </div>
                </div>
              </div>
              <div className="right-side row">
                {FEED_ITEMS.map((feedItem) => this.renderFeedItem(feedItem))}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

SelectFeedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,

  apiContentFolderGetAllStore: MobxPropTypes.observableObject,
  title: PropTypes.string,
};

SelectFeedModal.defaultProps = {
  title: DEFAULT_TITLE,
};

SelectFeedModal.wrappedComponent = {};
SelectFeedModal.wrappedComponent.propTypes = {
  apiContentFolderGetAllStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(SelectFeedModal)(
  observer(SelectFeedModal)
);
