import React from 'react';

import './waveGraphic.scss';

/**
 * The wave graphic component used on the login/signup pages.
 *
 * @returns {{}}
 */
const WaveGraphic = () => {
  return (
    <div className="wave-graphic">
      <div className="wave" />
      <div className="wave" />
    </div>
  );
};

export default WaveGraphic;
