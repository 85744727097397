/**
 * Gets the text data for a placeholder text.
 *
 * @returns {{text: {markdown: string}}}
 */
export function getPlaceHolderTextData() {
  return {
    text: {
      markdown: '{\\}{$1.00}{%1}{100}{#777777}{arial}New Text{!arial}{!#777777}{!100}{!%1}{!$1.00}{!\\}',
    },
  };
}
