import classNames from 'classnames';
import lodash from 'lodash';
import {observable, action, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Alert} from 'reactstrap';

import ConfirmPlanSelectModal from './components/confirmPlanSelectModal/ConfirmPlanSelectModal';
import OrderDetails from './components/orderDetails/PlanOrderDetails';
import AlaCarteOrderDetails from './components/orderDetails/AlaCarteOrderDetails';
import SignManufacturerBanner from './components/signManufacturerBanner/SignManufacturerBanner';
import ChargebeeCheckout from '../../common/chargebeeCheckout/ChargebeeCheckout';
import PricingTabs from '../../common/pricingTabs/PricingTabs';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import OnboardUserSigns from './components/onboardUserSigns/OnboardUserSigns';
import inject from '../../hoc/injectHoc';
import {dashboardRoute} from '../../routePaths';
import logo from '../../../assets/images/horizontal-darkblue.png';
import {STATE_PRE} from '../../../constants/asyncConstants';
import {buildWordpressUrl} from '../../../utils/routeHelper';
import {PAYMENT_PLAN_MONTHLY} from '../../../constants/subscriptionPlanConstants';
import {ALA_CARTE_CREDITS} from '../../../constants/pricingConstants';

import './onboardingPage.scss';

const SECTION_1 = 'section1';
const SECTION_2 = 'section2';

/**
 * The OnboardingPage component.
 */
export class OnboardingPage extends React.Component {
  /**
   * Current section displayed in the onboarding process
   * Should only use the constants SECTION_1, SECTION_2
   *
   * @type {string}
   */
  @observable currentSection = SECTION_1;

  /**
   * The currently selected plan.
   * This will be selected in the SubscriptionPlanTable component.
   *
   * @type {object}
   */
  @observable currentPlan = null;

  /**
   * The starting plan id.
   * This will clear when the plan is selected.
   *
   * @type {?number}
   */
  @observable startingPlanId = null;

  /**
   * The starting credit data.
   * This will clear when the plan is selected.
   *
   * @type {?number}
   */
  @observable startingAlaCarteCredit = null;

  /**
   * The a la carte credits the user wants to purchase
   *
   * @type {?{
   *  creditId: string,
   *  quantity: number
   * }}
   */
  @observable alaCarteCredits = null;

  /**
   * Chargebee Checkout is Loading
   *
   * @type {boolean}
   */
  @observable isLoadingChargebeeCheckout = false

  /**
   * Controls opening and closing chargebee checkout hosted page
   *
   * @type {boolean}
   */
  @observable chargebeeOpenCheckout = false;

  /**
   * Chargebee's hosted page details
   *
   * @type {{}}
   */
  @observable chargebeeHostedPage = null;

  /**
   * The order details billing type selected
   *
   * @type {string}
   */
  @observable billingType = PAYMENT_PLAN_MONTHLY;

  /**
   * Flag for if there is an error while loading chargebee checkout
   *
   * @type {boolean}
   */
  @observable hasChargebeeCheckoutError = false;

  /**
   * Flag for if user has no signs on submit
   *
   * @type {boolean}
   */
  @observable hasNoUserSignsError = false;

  /**
   * Flag for if user has already viewed the plan select pop-up
   *
   * @type {boolean}
   */
  @observable planSelectModalConfirmed = false;

  /**
   * Triggered when the component is first mounted to the page.
   */
  @action componentDidMount() {
    const {apiUserGetMeStore, apiUserOnboardStore, routerStore} = this.props;

    // Reset the section to the first.
    this.currentSection = SECTION_1;

    const startingPlanId = this.getPlanId();
    if (startingPlanId) {
      this.startingPlanId = startingPlanId;
    }

    const startingCreditData = this.getCreditData();
    if (startingCreditData) {
      this.startingAlaCarteCredit = startingCreditData;
    }

    apiUserOnboardStore.clearAll();

    apiUserGetMeStore.refresh(true);

    apiUserGetMeStore.getPromise().then((user) => {
      const planId = lodash.get(user, 'projectContentCompany.planId');
      if (planId && !user.requiresPlanUpdate) {
        // If the user has already onboarded, then send them to the dashboard.
        routerStore.push(dashboardRoute, {
          replaceIfTo: true,
        });
      }
    });
  }

  /**
   * Gets the plan id from the url params.
   *
   * @param {{match: {params: {pid: number}}}=} props
   * @returns {?number}
   */
  getPlanId = (props) => {
    const planId = lodash.get(props || this.props, 'match.params.planId');
    if (!planId || !Number(planId)) {
      return null;
    }

    return Number(planId);
  };

  /**
   * Gets the credit id and qty from the url params.
   * @returns {{download: number, request: number} | null}
   */
  getCreditData = () => {
    const downloadCreditQty = lodash.get(this.props, 'match.params.downloadCreditQty');
    const requestCreditQty = lodash.get(this.props, 'match.params.requestCreditQty');

    if (!Number(downloadCreditQty) && !Number(requestCreditQty)) {
      return null;
    }

    return {
      download: Number(downloadCreditQty),
      creditQty: Number(requestCreditQty)
    };
  };

  /**
   * Changes the currently displayed section
   * Should only use the constants SECTION_1, SECTION_2
   *
   * @param {string} section
   * @param {boolean} force
   */
  @action changeSection = (section, force) => {
    if (force) {
      this.currentSection = section;
      return;
    }

    if ((section === SECTION_1)) {
      // reset currentPlan and alaCarteCredit if user backs out of plan page
      this.currentPlan = null;
      this.alaCarteCredits = null;
    }

    if ((section === SECTION_2) && (!this.currentPlan && !this.alaCarteCredits)) {
      // Don't let the user leave step 1 until they choose a plan or credits.
      return;
    }

    this.currentSection = section;
  };

  /**
   * Change the currently selected plan.
   *
   * @param {{}} plan The plan object from the database.
   * @param {boolean | undefined} force Force the plan selection
   */
  @action onPlanSelect = (plan, force) => {
    const {apiUserGetMeStore} = this.props;
    const user = apiUserGetMeStore.getFulfilled();

    this.currentPlan = plan;
    this.startingPlanId = null;

    const FREE_PLAN_NAMES = ['Basic', 'Trial', 'NextLED'];

    const companyManufacturerSignupType = lodash.get(user, 'company.manufacturerSignupType');
    const shouldShowPlanConfirmationModal = companyManufacturerSignupType && FREE_PLAN_NAMES.includes(plan.name);

    // if user is sign manufacturer customer show confirmation modal if they choose the included plan
    if (shouldShowPlanConfirmationModal && !this.planSelectModalConfirmed && !force) {
      this.isConfirmPlanSelectModalOpen = true;
      return;
    }

    this.props.onboardingStore.setPlanData(plan);

    this.changeSection(SECTION_2, true);
  };

  /**
   * Purchase an a la carte credit
   *
   * @param {{
   *  download: number,
   *  request: number
   * }} alaCarteCreditsParam
   */
  @action onPurchaseCredits = (alaCarteCreditsParam) => {
    this.alaCarteCredits = alaCarteCreditsParam;
    this.startingAlaCarteCredit = null;

    this.changeSection(SECTION_2);
  }

  /**
   * Change the entered property
   *
   * @param {string} key
   * @param {{}} value
   */
  @action onChange = (key, value) => {
    this[key] = value;
  }

  /**
   * Control the Chargebee Checkout process
   */
  @action checkoutWithChargebee = async () => {
    const {apiUserOnboardStore, apiCompanySignGetAllStore} = this.props;

    const userSigns = apiCompanySignGetAllStore.getFulfilled() || [];

    // reset errors
    this.hasNoUserSignsError = false;
    this.hasChargebeeCheckoutError = false;

    if (userSigns.length === 0 && (this.currentPlan && this.currentPlan.canManageSigns)) {
      this.hasNoUserSignsError = true;
      return;
    }

    let data = {
      subscription: null,
      addons: null,
    };

    if (this.currentPlan && this.currentPlan.id) {
      data.subscription = {
        planId: this.currentPlan.id,
        billingType: this.billingType,
      };
    }

    if (this.alaCarteCredits) {
      data.addons = [{
        itemId: ALA_CARTE_CREDITS.download.chargebeeId,
        quantity: this.alaCarteCredits.download
      }, {
        itemId: ALA_CARTE_CREDITS.request.chargebeeId,
        quantity: this.alaCarteCredits.request
      }];
    }

    try {
      runInAction('Set the Chargebee Checkout Loading', () => {
        this.isLoadingChargebeeCheckout = true;
      });
      const chargebeeCheckoutResponse = await apiUserOnboardStore.chargebeeCheckout(data);

      runInAction('Set the chargebee hosted page.', () => {
        this.chargebeeHostedPage = chargebeeCheckoutResponse;
        this.chargebeeOpenCheckout = true;
      });
    } catch (error) {
      runInAction('Error loading chargebee hosted page.', () => {
        // eslint-disable-next-line no-console
        console.log('Chargebee Error: ', error);
        this.hasChargebeeCheckoutError = true;
      });
    } finally {
      runInAction('Set the Chargebee Checkout Loading', () => {
        this.isLoadingChargebeeCheckout = false;
      });
    }
  }

  @action onSuccessfulChargebeeCheckout = () => {
    const {apiUserOnboardStore, onboardingStore, routerStore, apiUserPollMeStore} = this.props;
    this.chargebeeOpenCheckout = false;

    this.changeSection(SECTION_1, true);

    // Call the server onboarding.
    apiUserOnboardStore.makeRequest(
      onboardingStore.getDataForServer()
    );

    apiUserPollMeStore.pollThenUpdateMyUser();

    apiUserOnboardStore.getPromise().then(() => {
      routerStore.push(dashboardRoute, {
        replaceIfTo: true,
      });
    });
  }

  /**
   * Handles when the plan select is confirmed
   *
   * @param {bool} wasConfirmed
   */
  @action onConfirmSelectComplete = (wasConfirmed) => {
    this.planSelectModalConfirmed = true;
    this.isConfirmPlanSelectModalOpen = false;

    // If user doesn't want to use their NextLED credit continue to Basic Plan
    if (!wasConfirmed) {
      this.onPlanSelect(this.currentPlan, true);
    } else {
      this.currentPlan = null;
      this.startingPlanId = null;
    }
  }

  /**
   * Renders the current section of the onboarding process
   *
   * @returns {{}}
   */
  renderContent() {
    const {currentPlan, currentSection, chargebeeHostedPage, chargebeeOpenCheckout, isLoadingChargebeeCheckout} = this;
    const {apiUserOnboardStore, apiCompanySignGetAllStore, apiUserGetMeStore} = this.props;
    const isSaving = (apiUserOnboardStore.state !== STATE_PRE);
    const isSection1 = (!isSaving && currentSection === SECTION_1);
    const isSection2 = (!isSaving && currentSection === SECTION_2);
    const userSigns = apiCompanySignGetAllStore.getFulfilled();
    const user = apiUserGetMeStore.getFulfilled();
    const companyManufacturerSignupType = lodash.get(user, 'company.manufacturerSignupType');

    if (isSection1) {
      return (
        <div className="onboarding-content">
          <PricingTabs
            selectPlanId={this.startingPlanId}
            onPlanSelect={this.onPlanSelect}
            startingCredits={this.startingAlaCarteCredit}
            onPurchaseCredits={this.onPurchaseCredits}
            user={user}
            bannerSlot={companyManufacturerSignupType && <SignManufacturerBanner signManufacturer={companyManufacturerSignupType} />}
            tabSelected={this.startingAlaCarteCredit ? 'credits' : 'subscription'}
          />
          {this.isConfirmPlanSelectModalOpen && (<ConfirmPlanSelectModal
            onComplete={(wasConfirmed) => this.onConfirmSelectComplete(wasConfirmed)}
          />)}
        </div>
      );
    }

    const submitText = 'Confirm Purchase';
    const columnClasses = 'onboarding-box col-12 col-md-8';
    const canManageSigns = currentPlan ? currentPlan.canManageSigns : true;

    return (
      <div className="onboarding-content">
        {(!isSaving) && (isSection2) && (
          <button
            type="button"
            className="onboarding-back-button"
            onClick={() => this.changeSection(SECTION_1)}
          >
            ← Plan Selection
          </button>
        )}

        <div className="row">

          {(isSection2) && (
            <OnboardUserSigns canManageSigns={canManageSigns} />
          )}

          {(isSaving) && apiUserOnboardStore.case({
            pending: () => (
              <div className={classNames('onboarding-pending', columnClasses)}>
                <LoadingIcon />
              </div>
            ),
            rejected: () => (
              <div className={classNames('onboarding-rejected', columnClasses)}>
                <div className="alert alert-danger">
                  Something went wrong. Please refresh the page and try again.
                </div>
              </div>
            ),
            fulfilled: () => (
              <div className={classNames('onboarding-fulfilled', columnClasses)}>
                <div className="alert alert-success">
                  Save success. Redirecting you to your dashboard...
                </div>
              </div>
            ),
          })}

          {(currentPlan) && (
            <OrderDetails
              buttonText={submitText}
              isLoading={isLoadingChargebeeCheckout}
              plan={currentPlan}
              billingType={this.onChange}
              userSigns={userSigns}
              onSubmit={this.checkoutWithChargebee}
            />
          )}

          {(!currentPlan && this.alaCarteCredits) && (
            <AlaCarteOrderDetails
              alaCarteCredits={this.alaCarteCredits}
              buttonText={submitText}
              isLoading={isLoadingChargebeeCheckout}
              onSubmit={this.checkoutWithChargebee}
            />
          )}

          <ChargebeeCheckout
            openCheckout={chargebeeOpenCheckout}
            hostedPage={chargebeeHostedPage}
            onSuccessfulCheckout={this.onSuccessfulChargebeeCheckout}
          />
        </div>
      </div>
    );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {currentSection, hasChargebeeCheckoutError, hasNoUserSignsError} = this;
    const homeLink = buildWordpressUrl();

    return (
      <div id="onboarding-page" className="system-page full-height container-fluid">
        <div className={classNames('row nav p-3', {[currentSection]: true})}>
          <div className="active-page-dot" />
          <div className="active-page-line" />
          <div className="col-md-3 col-12 d-flex align-items-center">
            <div>
              <a href={homeLink}>
                <img className="logo ml-3 p-2" src={logo} alt="logo" />
              </a>
            </div>
          </div>
          <div className="col-6 d-none d-md-flex align-items-center justify-content-center">
            <div
              onClick={() => this.changeSection(SECTION_1)}
              className={classNames('section-button', {active: currentSection === SECTION_1})}
            >
              Plans
            </div>
            <div
              onClick={() => this.changeSection(SECTION_2)}
              className={classNames('section-button', {active: currentSection === SECTION_2})}
            >
              Sign Information
            </div>
          </div>
        </div>
        <div className="container">
          {(hasChargebeeCheckoutError) && (
            <Alert color="danger" className="signup-alert">
              There was an error loading the checkout page. Please try again. If the problem continues contact us at info@projectcontent.com
            </Alert>
          )}

          {(hasNoUserSignsError) && (
            <Alert color="danger" className="signup-alert">
              Please build out your sign profile by clicking + in order to proceed with a purchase.
            </Alert>
          )}

          {this.renderContent()}
        </div>
      </div>
    );
  }
}

OnboardingPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,

  apiCompanySignGetAllStore: MobxPropTypes.observableObject,
  apiUserGetMeStore: MobxPropTypes.observableObject,
  apiUserOnboardStore: MobxPropTypes.observableObject,
  apiUserPollMeStore: MobxPropTypes.observableObject,
  onboardingStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
};

OnboardingPage.wrappedComponent = {};
OnboardingPage.wrappedComponent.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  apiUserOnboardStore: MobxPropTypes.observableObject.isRequired,
  apiUserPollMeStore: MobxPropTypes.observableObject.isRequired,
  onboardingStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(OnboardingPage)(
  observer(OnboardingPage)
);
