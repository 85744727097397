import {actionGameResizeComponent} from '../display/components/action/actionGameResizeComponent';
import {ratioToNumber} from './mathHelper';
import {getSourceFromGame} from '../display/game';

/**
 * Change dimensions of displayEditorStore based on aspect ratio
 *
 * @param {Object} displayEditorStore
 * @param {string} newAspectRatio
 * @returns {void}
 */
export function changeDisplayEditorStoreDimensions(displayEditorStore, newAspectRatio) {
  const {content, game, sources} = displayEditorStore;

  if (!game || !content) {
    return;
  }

  const actionParams = {
    entityId: true,
  };

  // Store the current source in the displayEditorStore, otherwise we would lose all updates.
  const oldSource = getSourceFromGame(game);
  const safeSources = sources.toJSON();

  displayEditorStore.setSource(displayEditorStore.currentAspectRatio, oldSource);

  const currentResolution = {...game.resolution};

  const aspectMultiplier = ratioToNumber(newAspectRatio);

  let minHeight;
  let finalHeight;
  let finalWidth;

  // if source exists with given apsect ratio, use. else calculate new width and height
  if (safeSources[newAspectRatio]) {
    const foundSource = safeSources[newAspectRatio];
    finalHeight = foundSource.resolution.height;
    finalWidth = foundSource.resolution.width;
  } else {
    minHeight = content.width / aspectMultiplier;
    finalHeight = minHeight + ((content.height - minHeight) / 2);
    finalWidth = finalHeight * aspectMultiplier;
  }

  game.addAction(actionParams, actionGameResizeComponent(
    newAspectRatio,
    finalWidth,
    finalHeight,
    currentResolution.width,
    currentResolution.height,
  ));
}
