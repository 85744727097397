import classNames from 'classnames';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';

import LoadingIcon from '../../../../common/loadingIcon/LoadingIcon';
import inject from '../../../../hoc/injectHoc';
import ConfirmModal from '../../../../modals/confirm/ConfirmModal';
import SignAddEditModal from '../../../../modals/signAddEdit/SignAddEditModal';
import {STATE_PENDING} from '../../../../../constants/asyncConstants';
import {CANNOT_ADD_SIGN_TEXT} from '../../../../../constants/messageConstants';

import './onboardUserSigns.scss';

const signFieldMap = [
  {label: 'Manufacturer', field: 'manufacturer'},
  {label: 'AspectRatio', field: 'aspectRatio'},
  {label: 'Pitch', field: 'pitch', postfix: 'mm'},
];

/**
 * The OnboardUserSigns component.
 */
export class OnboardUserSigns extends Component {
  /**
   * Whether or not the add sign modal should be open.
   *
   * @type {boolean}
   */
  @observable shouldAddSign = false;

  /**
   * The sign object for the sign to be edited.
   * This will open the edit sign modal.
   *
   * @type {?{}}
   */
  @observable editSign = null;

  /**
   * The sign object for the sign to be deleted.
   * This will open the delete sign confirm modal.
   *
   * @type {?{}}
   */
  @observable deleteSign = null;

  /**
   * Triggered when the component is first mounted to the page.
   */
  componentDidMount() {
    const {apiUserGetMeStore, apiCompanySignGetAllStore} = this.props;

    apiUserGetMeStore.refresh();
    apiCompanySignGetAllStore.refresh();
  }

  /**
   * Triggered when the add sign button is clicked.
   */
  @action onAddSign = () => {
    this.shouldAddSign = true;
    this.editSign = null;
    this.deleteSign = null;
  };

  /**
   * Triggered when the add sign modal is closed.
   */
  @action afterAddSign = () => {
    this.shouldAddSign = false;
  };

  /**
   * Triggered when the edit sign button is clicked.
   *
   * @param {{id: number}} sign
   * @returns {function}
   */
  onEditSign = (sign) => {
    return action('openEditSignModal', () => {
      this.editSign = sign;
      this.shouldAddSign = false;
      this.deleteSign = null;
    });
  };

  /**
   * Triggered when the edit sign modal is closed.
   */
  @action afterEditSign = () => {
    this.editSign = null;
  };

  /**
   * Triggered when the delete sign button is clicked.
   *
   * @param {{id: number}} sign
   * @returns {function}
   */
  onDeleteSign = (sign) => {
    return action('openDeleteSignConfirm', () => {
      this.deleteSign = sign;
      this.shouldAddSign = false;
      this.editSign = null;
    });
  };

  /**
   * Triggered when the user confirms the sign should be deleted.
   *
   * @param {boolean} wasConfirmed
   */
  @action afterConfirmDelete = (wasConfirmed) => {
    const {apiSignDeleteStore} = this.props;

    const sign = this.deleteSign;

    this.deleteSign = null;

    if (!wasConfirmed) {
      return;
    }

    apiSignDeleteStore.makeRequest(sign.id);
  };

  /**
   * Parses the signs into the cards.
   *
   * @param {Array<{}>} signs
   * @returns {Array<{}>}
   */
  parseSignsToCard = (signs) => {
    const {apiSignDeleteStore, canManageSigns} = this.props;

    return signs.map((sign) => {
      const deleteState = apiSignDeleteStore.getState(sign.id);
      const deleteIsPending = (deleteState === STATE_PENDING);

      let dimensions = null;
      if (sign.width && sign.height) {
        dimensions = `${sign.width} x ${sign.height}`;
      } else if (sign.width) {
        dimensions = `Width: ${sign.width}px`;
      } else if (sign.height) {
        dimensions = `Height: ${sign.height}px`;
      }

      return (
        <div className="card" key={sign.id}>
          <div className="card-body">
            <h5 className="card-title">{sign.name}</h5>

            {(dimensions) && (
              <h6 className="card-subtitle mb-2 text-muted">{dimensions}</h6>
            )}

            <ul className="card-text">
              {signFieldMap.map(({label, field, postfix}) => (
                <li key={field}>{label}: {sign[field]}{postfix || ''}</li>
              ))}
            </ul>

            {canManageSigns && (<div>
              <button
                type="button"
                className="btn btn-sm btn-primary card-link"
                onClick={this.onEditSign(sign)}
              >Edit</button>

              <button
                type="button"
                className="btn btn-sm btn-danger card-link"
                onClick={this.onDeleteSign(sign)}
                disabled={deleteIsPending}
              >{(deleteIsPending) ? 'Deleting...' : 'Delete'}</button>
            </div>)}
          </div>
        </div>
      );
    });
  };

  /**
   * Renders the signs.
   *
   * @param {{}} user
   * @returns {{}}
   */
  renderSigns = () => {
    const {apiCompanySignGetAllStore, canManageSigns} = this.props;

    return apiCompanySignGetAllStore.case({
      pre: () => <LoadingIcon />,
      pending: () => <LoadingIcon />,
      rejected: () => (
        <div className="alert alert-warning">
          The list of signs could not be loaded.
        </div>
      ),
      fulfilled: (signs) => (
        <div className="card-deck">
          {this.parseSignsToCard(signs)}

          {canManageSigns && (<div className="card add-new-card">
            <div className="card-body">
              <button
                type="button"
                className="btn add-new-button"
                onClick={this.onAddSign}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  size="2x"
                />
              </button>
            </div>
          </div>)}
        </div>
      ),
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiUserGetMeStore, canManageSigns} = this.props;

    const user = apiUserGetMeStore.getFulfilled();
    const columnClasses = 'col-12 col-md-8';

    return (
      <div id="onboard-user-signs" className={classNames('system-page', 'full-height', 'container-fluid', columnClasses)}>
        <div className="container">
          <h2>Your Signs</h2>
          {canManageSigns && (<div className="signs-header-subtext">(One sign included with your plan)</div>)}

          {apiUserGetMeStore.case({
            pre: () => <LoadingIcon />,
            pending: () => <LoadingIcon />,
            rejected: () => (
              <div className="alert alert-warning">
                The list of signs could not be loaded.
              </div>
            ),
            fulfilled: (meUser) => this.renderSigns(meUser),
          })}
          <div className="signs-header-subtext">{CANNOT_ADD_SIGN_TEXT}</div>
        </div>

        {(this.deleteSign) && (
          <ConfirmModal
            isOpen={true}
            confirmText={`Are you sure you want to delete ${this.deleteSign.name}?`}
            onComplete={this.afterConfirmDelete}
            isYesNo={true}
          />
        )}

        {(this.shouldAddSign) && (
          <SignAddEditModal
            isOpen={true}
            isAdmin={false}
            allowSizeEdit={true}
            userId={(user) ? user.projectContentUserId : null}
            onComplete={this.afterAddSign}
            confirmBodyText=""
          />
        )}

        {(this.editSign) && (
          <SignAddEditModal
            isOpen={true}
            isAdmin={false}
            allowSizeEdit={true}
            sign={this.editSign}
            userId={(user) ? user.projectContentUserId : null}
            onComplete={this.afterEditSign}
            confirmBodyText=""
          />
        )}
      </div>
    );
  }
}

OnboardUserSigns.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject,
  apiSignDeleteStore: MobxPropTypes.observableObject,
  apiUserGetMeStore: MobxPropTypes.observableObject,
  canManageSigns: PropTypes.bool,
};

OnboardUserSigns.defaultProps = {
  canManageSigns: true,
};

OnboardUserSigns.wrappedComponent = {};
OnboardUserSigns.wrappedComponent.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiSignDeleteStore: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(OnboardUserSigns)(
  observer(OnboardUserSigns)
);
