import lodash from 'lodash';
import {observe} from 'mobx';

import auth0 from './auth0';
import {parseSearchParams} from './routeHelper';
import {
  dashboardRoute,
  homeRoute,
  onboardingRoute,
  signupRoute,
  userPaymentRoute,
  userPaymentRouteNoPlanId
} from '../components/routePaths';
import routerStore from '../stores/active/routerStore';
import serverRequest from './serverRequest';
import {USER_ROLE} from '../constants/userConstants';

const isUserSubscriber = (user) => {
  if (!user || !user.projectContentUserId || !user.projectContentCompany) {
    return false;
  }
  const userIsElevated = user.role && user.role !== USER_ROLE;

  return user.projectContentCompany.subscriberUserId === user.projectContentUserId || !user.projectContentCompany.subscriberUserId && userIsElevated;
};

/**
 * The history object.
 *
 * @type {?{}}
 */
export let routeHistory = {
  push: function dummyHistoryPush() {
    throw new Error('The routeHistory object has not yet been registered.');
  }
};

/**
 * Syncs the history with the router store.
 *
 * @param {{}} theHistory
 * @returns {{}}
 */
export const syncHistoryWithStore = (theHistory) => {
  // Initialise store
  routerStore.history = theHistory;

  // Handle update from history object
  const handleLocationChange = (newLocation) => {
    routerStore._setLocation(newLocation);
  };

  const unsubscribeFromHistory = theHistory.listen(handleLocationChange);
  handleLocationChange(theHistory.location);

  theHistory.subscribe = (listener) => {
    const onStoreChange = () => {
      const rawLocation = {...routerStore.location};
      listener(rawLocation, theHistory.action);
    };

    // Listen for changes to location state in store
    const unsubscribeFromStore = observe(routerStore, 'location', onStoreChange);

    listener(routerStore.location, theHistory.action);

    return unsubscribeFromStore;
  };

  theHistory.unsubscribe = unsubscribeFromHistory;

  return theHistory;
};

/**
 * Registers a new routeHistory object.
 *
 * @param {{}} newHistory
 */
export function registerHistory(newHistory) {
  routeHistory = newHistory;

  syncHistoryWithStore(newHistory);
}

/**
 * Attaches a listener to the router history.
 *
 * @param {Object<string, {}>} stores
 */
export function attachListenersToHistory(stores) {
  if (!stores.routerStore) {
    throw new Error('The router store must be defined before you can attach listeners to it.');
  }

  const listeners = {
    newUserToSignUp: newUserToSignUp(stores),
    signupToRouteBasedOnLoginStatus: signupToRouteBasedOnLoginStatus(stores),
    userBadPlanToUserPayment: userBadPlanToUserPayment(stores),
    userRequiresPlanUpdate: userRequiresPlanUpdate(stores)
  };

  lodash.forEach(listeners, (listener, listenerName) => {
    stores.routerStore.registerListener(listenerName, listener);
    listener();
  });
}

/**
 * Forces the user to the signup page if they don't have a hub user or Project Content user
 * but do have an Auth0 user.
 *
 * @param {Object<string, {}>} stores
 * @returns {function}
 */
function newUserToSignUp(stores) {
  const {apiUserGetMeStore} = stores;

  if (!stores.routerStore || !apiUserGetMeStore) {
    return lodash.noop;
  }

  return () => {
    // guard applies to all routes except these routes
    if (stores.routerStore.isSameAs([signupRoute, onboardingRoute])) {
      return;
    }

    apiUserGetMeStore.refresh();
    apiUserGetMeStore.getPromise()
      .then((pcUser) => {
        if (pcUser && !pcUser.projectContentUserId) {
          stores.routerStore.replace(signupRoute);
        }
      })
      .catch((apiUserGetMeError) => {
        auth0.getUser().then((auth0User) => {
          if (auth0User && apiUserGetMeError && apiUserGetMeError.statusCode === serverRequest.UNAUTHORIZED_STATUS_CODE) {
            stores.routerStore.replace(signupRoute);
          }
        });
      });
  };
}

/**
 * Forces the user to the Auth0 signup if they do not have an Auth0 user.
 * Forces user to dashboard if they try to go to the signup route and already have a PC user.
 *
 * @param {Object<string, {}>} stores
 * @returns {function}
 */
function signupToRouteBasedOnLoginStatus(stores) {
  const {apiUserGetMeStore} = stores;

  if (!stores.routerStore || !apiUserGetMeStore) {
    return lodash.noop;
  }

  return () => {
    // guard only applies to these routes
    if (!stores.routerStore.isSameAs([signupRoute, onboardingRoute])) {
      return;
    }

    // If Project Content User already exists, go to dashboard.
    apiUserGetMeStore.getPromise().then((userMe) => {
      const userExistsInProjectContent = userMe && userMe.projectContentUserId;
      const userIsNotSubscriberUser = !isUserSubscriber(userMe);
      const userCompanyHasPlan = userMe.projectContentCompany && userMe.projectContentCompany.planId;
      if (userMe && userExistsInProjectContent && (userCompanyHasPlan && userIsNotSubscriberUser)) {
        stores.routerStore.replace(dashboardRoute);
      }
    }).catch(() => {
    // If Project Content User DNE and Auth0 User isn't logged in go to Auth0 Signup
      auth0.getUser().then((auth0User) => {
        if (!auth0User) {
          auth0.loginWithRedirect({
            authorizationParams: {
              'screen_hint': 'signup',
            }
          });
        }
      });
    });
  };
}

/**
 * Forces the user to the onboarding page if they do not have a plan.
 *
 * @param {Object<string, {}>} stores
 * @returns {function}
 */
function userBadPlanToUserPayment(stores) {
  const {
    /* @type {ApiUserGetMeStore} */ apiUserGetMeStore,
    /* @type {ApiCompanySignGetAllStore} */ apiCompanySignGetAllStore,
  } = stores;

  if (!stores.routerStore || !apiUserGetMeStore) {
    return lodash.noop;
  }

  return () => {
    // guard applies to all routes except these routes
    if (stores.routerStore.isSameAs([signupRoute, onboardingRoute, userPaymentRoute, homeRoute])) {
      return;
    }

    apiUserGetMeStore.refresh();
    apiUserGetMeStore.getPromise().then((userMe) => {
      if (!userMe) {
        return Promise.reject('User does not exist.');
      }

      const userIsSubscriber = isUserSubscriber(userMe);
      const companyHasPlan = userMe.projectContentCompany && userMe.projectContentCompany.planId;
      if (!userIsSubscriber || companyHasPlan) {
        return Promise.reject('User is not the subscriber or company already has a selected plan.');
      }

      apiCompanySignGetAllStore.refresh();
      return apiCompanySignGetAllStore.getPromise();
    }).then((userSigns) => {
      if (userSigns.length > 0) {
        // authenticated users with a valid sign go to the userPaymentRoute
        stores.routerStore.replace(userPaymentRouteNoPlanId, {
          ignoreTo: true,
          params: {
            planId: '',
          },
        });
      } else {
        // authenticated users without a valid sign goes to the onboardingRoute
        stores.routerStore.replace(onboardingRoute, {
          ignoreTo: true,
          params: {
            planId: '',
          },
        });
      }
    });
  };
}

/**
 * Forces the user to the onboarding page if they need to update their plan.
 *
 * @param {Object<string, {}>} stores
 * @returns {void}
 */
function userRequiresPlanUpdate(stores) {
  const {
    /* @type {ApiUserGetMeStore} */ apiUserGetMeStore,
  } = stores;

  return () => {
    // guard applies to all routes except these routes
    if (stores.routerStore.isSameAs([onboardingRoute, userPaymentRoute, homeRoute])) {
      return;
    }

    apiUserGetMeStore.refresh();
    apiUserGetMeStore.getPromise().then((userMe) => {
      if (!userMe) {
        return;
      }

      const userIsSubscriber = isUserSubscriber(userMe);

      if (!userIsSubscriber || (userMe.projectContentCompany && !userMe.projectContentCompany.requiresPlanUpdate)) {
        return;
      }

      stores.routerStore.replace(userPaymentRoute, {
        ignoreTo: true,
        params: {
          planId: '',
        },
      });
    });
  };
}

/**
 * Blocks navigation away from the page.
 *
 * @param {function} callback
 * @returns {function} Unblocks the transition.
 */
export function blockTransition(callback) {
  return routerStore.blockRouting(callback);
}

/**
 * Whether or not the match route (or current route) matches one or more of the given routes.
 *
 * @param {string|string[]} otherRoutes
 * @param {?string} matchRoute
 * @returns {boolean}
 */
export function routeMatches(otherRoutes, matchRoute) {
  let safeOtherRoutes = otherRoutes;
  if (!Array.isArray(otherRoutes)) {
    safeOtherRoutes = [otherRoutes];
  }

  let safeMatchRoute = matchRoute;
  if (!matchRoute) {
    if (!routeHistory || !routeHistory.location) {
      throw new Error('Could not match route as no routeHistory location is defined.');
    }
    safeMatchRoute = routeHistory.location.pathname;
  }

  return lodash.some(safeOtherRoutes, (otherRoute) => {
    const hasParams = (otherRoute.indexOf(':') > -1);
    if (!hasParams) {
      return lodash.startsWith(safeMatchRoute, otherRoute);
    }

    const otherRouteRegexp = new RegExp(otherRoute.replace(/:[^/]+/ig, '[^/]+'));
    return safeMatchRoute.match(otherRouteRegexp);
  });
}

/**
 * Redirects to the given route unless the `to` search param is set.
 *
 * @param {string} toRoute
 * @param {boolean} replaceIfTo
 */
export function redirect(toRoute, replaceIfTo) {
  routerStore.push(toRoute, {
    replaceIfTo,
  });
}

/**
 * Gets a redirection path.
 *
 * @param {string} newLocationRoute
 * @param {string=} overrideTo
 * @returns {{pathname: string, search: string}}
 */
export function getRedirectWithTo(newLocationRoute, overrideTo) {
  if (overrideTo) {
    const safeOverrideTo = String(overrideTo);
    const toWithoutPreSlash = (safeOverrideTo[0] === '/') ? safeOverrideTo.substr(1) : safeOverrideTo;
    return {
      pathname: newLocationRoute,
      search: `?to=${toWithoutPreSlash}`,
    };
  }

  const to = parseSearchParams(routeHistory, 'to');
  if (to) {
    const toWithoutPreSlash = (to[0] === '/') ? to.substr(1) : to;
    return {
      pathname: newLocationRoute,
      search: `?to=${toWithoutPreSlash}`,
    };
  }

  return newLocationRoute;
}
